import Header from "../components/Header";

import { useEffect, useState, useCallback } from "react";

import { API } from "aws-amplify";

import Loader from "../components/Loader";

import { Transaction } from "billeci-private-sitebuilder-schema/dist/API";
import { getRoutingTransactions } from "billeci-private-sitebuilder-schema/dist/graphql/queries";

import { CheckCircleIcon } from "@heroicons/react/solid";

import TimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en.json";

TimeAgo.addLocale(en);
const timeAgo = new TimeAgo("en-US");

export default function Routing() {
    const [transactions, transactionsSet] = useState([] as Transaction[]);
    const [currentTransaction, currentTransactionSet] = useState({} as Partial<Transaction>);
    const [loading, loadingSet] = useState(true);

    const doParseCurrent = (t: Transaction[]) => {
        if (t) {
            currentTransactionSet(t[0]);
        }
    };

    const loadData = useCallback(async () => {
        const response: any = await API.graphql({ query: getRoutingTransactions });
        doParseCurrent(response.data.getRoutingTransactions);
        transactionsSet(response.data.getRoutingTransactions);
        loadingSet(false);
    }, []);

    useEffect(() => {
        loadData();
    }, [loadData]);
    return (
        <>
            <Header
                title="Routing"
                action_button={{
                    text: "Reload",
                    callback: async () => {
                        await loadData();
                    },
                }}
            />
            <main>
                {loading ? (
                    <Loader />
                ) : (
                    <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
                        <div className="flex flex-col">
                            <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                                    {currentTransaction && currentTransaction.description === "ROUTING_START" && (
                                        <div className="rounded-md bg-green-50 p-4 m-4">
                                            <div className="flex">
                                                <div className="flex-shrink-0">
                                                    <CheckCircleIcon
                                                        className="h-5 w-5 text-green-400"
                                                        aria-hidden="true"
                                                    />
                                                </div>
                                                <div className="ml-3">
                                                    <h3 className="text-sm font-medium text-green-800">
                                                        DNS Routing Deployment in Progress
                                                    </h3>
                                                    <div className="mt-2 text-sm text-green-700">
                                                        <p>
                                                            This is an automated process. The system checks for updates
                                                            to DNS/CDN/URI every 5 minutes. Each update takes
                                                            approximately 20 minutes.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                                        <table className="min-w-full divide-y divide-gray-200">
                                            <thead className="bg-gray-50">
                                                <tr>
                                                    <th
                                                        scope="col"
                                                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                                    >
                                                        Type
                                                    </th>
                                                    <th
                                                        scope="col"
                                                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                                    >
                                                        Relative
                                                    </th>
                                                    <th
                                                        scope="col"
                                                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                                    >
                                                        Timestamp
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody className="bg-white divide-y divide-gray-200">
                                                {transactions &&
                                                    transactions.map((t, index) => (
                                                        <tr
                                                            key={index}
                                                            className={index % 2 === 0 ? "bg-gray-100" : ""}
                                                        >
                                                            <td className="px-6 py-4 whitespace-nowrap">
                                                                <div className="text-sm text-gray-900">
                                                                    {t.description}
                                                                </div>
                                                            </td>
                                                            <td className="px-6 py-4 whitespace-nowrap">
                                                                <div className="text-sm text-gray-900">
                                                                    {timeAgo.format(new Date(t.added))}
                                                                </div>
                                                            </td>
                                                            <td className="px-6 py-4 whitespace-nowrap">
                                                                <div className="text-sm text-gray-900">{t.added}</div>
                                                            </td>
                                                        </tr>
                                                    ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </main>
        </>
    );
}
