import { useState, useEffect } from "react";

import Header from "../components/Header";
import Loader from "../components/Loader";

import { Link } from "react-router-dom";
import Confirm, { ModalInfo } from "../components/Confirm";
import { API } from "aws-amplify";

import { Site, SiteViewerResponse, Transaction } from "billeci-private-sitebuilder-schema/dist/API";
import { getSiteViewersByStatus } from "billeci-private-sitebuilder-schema/dist/graphql/queries";

import { CheckCircleIcon } from "@heroicons/react/solid";

import { classNames } from "../utils/utils";

import TimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en.json";

TimeAgo.addLocale(en);
const timeAgo = new TimeAgo("en-US");

function Sites() {
    const [sites, sitesSet] = useState([] as Site[]);
    const [realtimeActive, realtimeActiveSet] = useState([] as string[]);
    const [loading, loadingSet] = useState(true);
    const [modalInfo, setModalInfo] = useState<ModalInfo>({
        title: "Title",
        text: "Text",
        positive: "OK",
        negative: "Cancel",
        close: () => {
            setModalInfo({
                ...modalInfo,
                open: false,
            });
        },
        action: () => {},
        open: false,
    });

    const doDeploy = async (site_id: string) => {
        const deploySite = /* GraphQL */ `
            mutation DeploySite($id: String!) {
                deploySite(id: $id) {
                    ip
                    status
                    siteId
                }
            }
        `;
        try {
            await API.graphql({ query: deploySite, variables: { id: site_id } });
        } catch (error) {
            console.log(error);
        }
    };
    const getSites = /* GraphQL */ `
        query GetSites {
            getSites {
                id
                uri
                surveySystemEnable
                surveySystemSourceId
                lockedSourceId
                app {
                    id
                    name
                    added
                    updated
                    lockTort
                }
                survey {
                    id
                    name
                    app {
                        id
                    }
                    added
                    updated
                }
                domain {
                    id
                    name
                }
                content {
                    id
                    name
                    app {
                        id
                    }
                    added
                    updated
                }
                firm {
                    id
                    name
                    displayName
                }
                deploymentTransactions {
                    id
                    added
                }
                added
                updated
                deployActive
            }
        }
    `;

    const sortResults = (sites: Site[]) => {
        return sites.sort((a: Site, b: Site) => {
            let fa = `${a.domain?.name}${a.uri}`.toLowerCase(),
                fb = `${b.domain?.name}${b.uri}`.toLowerCase();
            if (fa < fb) {
                return -1;
            }
            if (fa > fb) {
                return 1;
            }
            return 0;
        });
    };

    useEffect(() => {
        const loadData = async () => {
            var promises = {};
            promises["site"] = await API.graphql({ query: getSites });
            promises["active"] = await API.graphql({ query: getSiteViewersByStatus, variables: { status: "ACTIVE" } });
            var query_results = await Promise.all([promises["site"], promises["active"]]);

            sitesSet(sortResults(query_results[0].data.getSites as Site[]));

            var realTimeActive: string[] = [];
            for (let svr of query_results[1].data.getSiteViewersByStatus as SiteViewerResponse[]) {
                realTimeActive.push(svr!.siteId!);
            }

            realtimeActiveSet(realTimeActive);
            loadingSet(false);
        };
        loadData();
    }, [getSites]);

    return (
        <>
            <Header title="Sites" action_button={{ link: "/sites/new", text: "Create New" }} />
            <main>
                {loading ? (
                    <Loader />
                ) : (
                    <div className="max-w-full w-7xl mx-auto py-6 sm:px-6 lg:px-8">
                        <div className="flex flex-col">
                            <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                                    <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                                        <table className="min-w-full divide-y divide-gray-200">
                                            <thead className="bg-gray-50">
                                                <tr>
                                                    <th
                                                        scope="col"
                                                        className="px-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                                    ></th>
                                                    <th
                                                        scope="col"
                                                        className="pr-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                                    >
                                                        URL
                                                    </th>
                                                    <th
                                                        scope="col"
                                                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                                    >
                                                        Status
                                                    </th>
                                                    <th
                                                        scope="col"
                                                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                                    >
                                                        Firm
                                                    </th>
                                                    <th
                                                        scope="col"
                                                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                                    >
                                                        App
                                                    </th>
                                                    <th
                                                        scope="col"
                                                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                                    >
                                                        Survey
                                                    </th>
                                                    <th
                                                        scope="col"
                                                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                                    >
                                                        Content
                                                    </th>
                                                    {/* <th
                                                        scope="col"
                                                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                                    >
                                                        Status
                                                    </th> */}
                                                    <th scope="col" className="relative px-6 py-3">
                                                        <span className="sr-only">Edit</span>
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody className="bg-white divide-y divide-gray-200">
                                                {sites.map((site: any, index: number) => (
                                                    <tr key={index} className={index % 2 === 0 ? "bg-gray-100" : ""}>
                                                        <td className="px-1 py-4 whitespace-nowrap text-sm justify-center text-gray-500">
                                                            {realtimeActive.includes(site.id) && (
                                                                <CheckCircleIcon
                                                                    className="h-5 w-5 text-green-400"
                                                                    aria-hidden="true"
                                                                />
                                                            )}
                                                        </td>
                                                        <td className="pr-6 py-4 whitespace-nowrap">
                                                            <div className="text-sm text-gray-900">
                                                                <a
                                                                    href={`https://www.${site.domain?.name}${site.uri}`}
                                                                    target="_blank"
                                                                    rel="noreferrer"
                                                                >
                                                                    {site.domain?.name}
                                                                    {site.uri === "/" ? "" : site.uri}
                                                                </a>
                                                            </div>
                                                        </td>
                                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                                            {site.deployActive !== false && (
                                                                <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                                                                    Active
                                                                </span>
                                                            )}
                                                            {site.deployActive === false && (
                                                                <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800">
                                                                    Inactive
                                                                </span>
                                                            )}
                                                        </td>
                                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                                            {site.firm?.name}
                                                            {site.firm?.displayName
                                                                ? ` (${site.firm?.displayName})`
                                                                : ""}
                                                        </td>
                                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                                            {site.app?.name}
                                                        </td>
                                                        <td
                                                            className={classNames(
                                                                `px-6 py-4 whitespace-nowrap text-sm text-gray-500`,
                                                                site.surveySystemEnable || site.app.lockTort
                                                                    ? `font-bold`
                                                                    : ``
                                                            )}
                                                        >
                                                            {site.surveySystemEnable ? (
                                                                `(V2) - ${site.surveySystemSourceId}`
                                                            ) : site.app.lockTort ? (
                                                                `(LOCK) ${site.lockedSourceId}`
                                                            ) : (
                                                                <Link
                                                                    to={`/surveys/${site.survey?.id}`}
                                                                    className="text-indigo-600 hover:text-indigo-900 pr-2"
                                                                >
                                                                    {site.survey?.name}
                                                                </Link>
                                                            )}
                                                        </td>
                                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                                            <Link
                                                                to={`/contents/${site.content?.id}`}
                                                                className="text-indigo-600 hover:text-indigo-900 pr-2"
                                                            >
                                                                {site.content?.name}
                                                            </Link>
                                                        </td>
                                                        {/* <td className="px-6 py-4 whitespace-nowrap">
                                                            <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                                                                Up To Date
                                                            </span>
                                                        </td> */}
                                                        <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium pt-4">
                                                            <Link
                                                                to={`/sites/${site.id}`}
                                                                className="text-indigo-600 hover:text-indigo-900 pr-2"
                                                            >
                                                                Edit
                                                            </Link>
                                                            {site.deployActive !== false && (
                                                                <>
                                                                    {" "}
                                                                    |
                                                                    <button
                                                                        onClick={() =>
                                                                            setModalInfo({
                                                                                ...modalInfo,
                                                                                open: true,
                                                                                title: "DEPLOY",
                                                                                text: `Are you sure you wish to DEPLOY this version of the site?`,
                                                                                action: () => {
                                                                                    doDeploy(site.id);
                                                                                },
                                                                            })
                                                                        }
                                                                        className="pl-2 text-indigo-600 hover:text-indigo-900"
                                                                    >
                                                                        Deploy
                                                                    </button>
                                                                </>
                                                            )}
                                                            {site.deploymentTransactions &&
                                                                (site.deploymentTransactions as Transaction[]).length >
                                                                    0 && (
                                                                    <p className="text-xs text-gray-500">
                                                                        (last deploy:{" "}
                                                                        {timeAgo.format(
                                                                            new Date(
                                                                                (
                                                                                    site.deploymentTransactions as Transaction[]
                                                                                )[
                                                                                    (
                                                                                        site.deploymentTransactions as Transaction[]
                                                                                    ).length - 1
                                                                                ].added!
                                                                            )
                                                                        )}
                                                                        )
                                                                    </p>
                                                                )}
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </main>
            {modalInfo.open && <Confirm info={modalInfo} />}
        </>
    );
}

export default Sites;
