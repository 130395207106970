import { useState, useEffect, useCallback } from "react";
import { useStore } from "effector-react";

import RealtimeStore, { addRealtimeMessage, updateRealtimeStore } from "../stores/RealtimeStore";

import * as Observable from "zen-observable";

import { useParams } from "react-router-dom";

import Confirm, { ModalInfo } from "../components/Confirm";

import { subscribeToAddSiteRealtimeEvent } from "billeci-private-sitebuilder-schema/dist/graphql/subscriptions";

import { API } from "aws-amplify";

import TimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en.json";

import { v4 as uuidv4 } from "uuid";

import { doRefreshNotify } from "../methods/notifyRealtime";
import { RealtimeEvent, SiteViewerResponse, Site } from "billeci-private-sitebuilder-schema/dist/API";

TimeAgo.addLocale(en);
const timeAgo = new TimeAgo("en-US");

type routeParams = {
    site_id: string;
};

export default function SitesRealtime() {
    const route_params: routeParams = useParams();
    const realtime_store = useStore(RealtimeStore);
    const [modalInfo, setModalInfo] = useState<ModalInfo>({
        title: "Title",
        text: "Text",
        positive: "OK",
        negative: "Cancel",
        close: () => {
            setModalInfo({
                ...modalInfo,
                open: false,
            });
        },
        action: () => {},
        open: false,
    });

    const doDeploy = async (site_id: string) => {
        const deploySite = /* GraphQL */ `
            mutation DeploySite($id: String!) {
                deploySite(id: $id) {
                    ip
                    status
                    siteId
                }
            }
        `;
        try {
            await API.graphql({ query: deploySite, variables: { id: site_id } });
        } catch (error) {
            console.log(error);
        }
    };

    const doDestroy = async (site_id: string) => {
        const destroySiteViewer = /* GraphQL */ `
            mutation DestroySiteViewer($id: String!) {
                destroySiteViewer(id: $id) {
                    ip
                    status
                    siteId
                }
            }
        `;
        try {
            const response: any = await API.graphql({ query: destroySiteViewer, variables: { id: site_id } });
            var responseData = response.data.destroySiteViewer as SiteViewerResponse;
            updateRealtimeStore({
                siteviewerResponse: responseData,
            });
        } catch (error) {
            console.log(error);
        }
    };

    const loadData = useCallback(async () => {
        if (route_params.site_id) {
            const getSiteViewer = /* GraphQL */ `
                query GetSiteViewer($id: String!) {
                    getSiteViewer(id: $id) {
                        ip
                        status
                        siteId
                    }
                }
            `;
            const getSiteInfo = /* GraphQl */ `
                query GetSiteInfo($id: ID!) {
                    getSite(id: $id) {
                        id
                        uri
                        app {
                            name
                        }
                        domain {
                            name
                        }
                        firm {
                            name
                        }
                        content {
                            name
                        }
                        survey {
                            name
                        }
                    }
                }
            `;
            try {
                var promises: any = {};
                var graphQlValues: any;
                promises["response"] = API.graphql({ query: getSiteViewer, variables: { id: route_params.site_id } });
                promises["site"] = API.graphql({ query: getSiteInfo, variables: { id: route_params.site_id } });
                graphQlValues = await Promise.all([
                    promises["response"], // 0
                    promises["site"], // 1
                ]);
                var responseData = graphQlValues[0].data.getSiteViewer as SiteViewerResponse;
                var siteResponseData = graphQlValues[1].data.getSite as Partial<Site>;
                console.log("response");
                console.log(graphQlValues);
                updateRealtimeStore({
                    siteviewerResponse: responseData,
                    site: siteResponseData,
                    framekey: uuidv4(),
                });
            } catch (error) {
                console.log(error);
            }
        }
    }, [route_params.site_id]);

    const getRealtimeUrl = () => {
        var hostname = window.location.hostname;
        var url = "";
        if (realtime_store.siteviewerResponse.ip === "localhost") {
            url = `http://localhost:3000`;
        } else {
            url = `https://${route_params?.site_id}.${hostname}`;
        }
        return url;
    };

    useEffect(() => {
        console.log("subscribing");
        async function doSub() {
            let r: Observable<object> = await API.graphql({
                query: subscribeToAddSiteRealtimeEvent,
            });
            console.log("subQdone");
            r.subscribe({
                next: ({ provider, value }: { provider: any; value: any }) => {
                    var msg = value.data.subscribeToAddSiteRealtimeEvent as RealtimeEvent;
                    if (msg) {
                        addRealtimeMessage(msg);
                    }
                },
                error: (error: any) => console.warn(error),
            });
            setTimeout(() => {
                console.log("runload");
                loadData();
            }, 1000);
        }
        doSub();
    }, [loadData]);

    return (
        <>
            <main className={`object-fill h-screen`}>
                <div className={`object-fill flex h-screen`}>
                    <div className={`w-96 flex-none bg-blue-500 h-screen border-r-4 border-black p-2 text-white`}>
                        <div className={`w-full text-sm`}>
                            <p>ID: {realtime_store.site?.id}</p>
                            <p>
                                URL: {realtime_store.site?.domain?.name ?? "#"}
                                {realtime_store.site?.uri ?? "/"}
                            </p>
                            <p>Firm: {realtime_store.site?.firm?.name}</p>
                            <p>Content: {realtime_store.site?.content?.name}</p>
                            <p>Survey: {realtime_store.site?.survey?.name}</p>
                            <p>App: {realtime_store.site?.app?.name}</p>
                        </div>
                        <p className="pt-4">Viewer Status: {realtime_store.siteviewerResponse?.status ?? "N/A"}</p>
                        <p>
                            ECS IP:{" "}
                            {realtime_store.siteviewerResponse?.ip
                                ? `${realtime_store.siteviewerResponse?.ip}:3000`
                                : "N/A"}
                        </p>
                        <p className={`font-bold text-black underline pt-4`}>JUMP TO:</p>
                        <button
                            className={`w-full bg-blue-200 border-blue-800 border-2 rounded mb-2 text-black`}
                            onClick={() => {
                                window.open(
                                    `https://${realtime_store.site?.domain?.name ?? "#"}${
                                        realtime_store.site?.uri ?? "/"
                                    }`
                                );
                            }}
                        >
                            Live Site
                        </button>
                        <button
                            className={`w-full bg-blue-200 border-blue-800 border-2 rounded mb-2 text-black`}
                            onClick={() => {
                                window.open(
                                    window.location.origin
                                        .replace("://", `://${route_params.site_id}.`)
                                        .replace(":4443", "")
                                );
                            }}
                        >
                            Remove iFrame
                        </button>
                        <p className={`font-bold text-black underline pt-4`}>Actions:</p>
                        <button
                            className={`w-full bg-green-200 border-blue-800 border-2 rounded mb-2 text-black`}
                            onClick={() =>
                                setModalInfo({
                                    ...modalInfo,
                                    open: true,
                                    title: "DEPLOY",
                                    text: `Are you sure you wish to DEPLOY this version of the site?`,
                                    action: () => {
                                        doDeploy(route_params.site_id);
                                    },
                                })
                            }
                        >
                            deploy to internet
                        </button>
                        <button
                            className={`w-full bg-blue-200 border-blue-800 border-2 rounded mb-2 text-black`}
                            onClick={() => {
                                loadData();
                            }}
                        >
                            force page reload
                        </button>
                        <button
                            className={`w-full bg-blue-200 border-blue-800 border-2 rounded mb-2 text-black`}
                            onClick={() => {
                                doRefreshNotify(route_params.site_id);
                            }}
                        >
                            refresh APP code from github
                        </button>
                        <button
                            className={`w-full bg-red-200 border-blue-800 border-2 rounded mb-2 text-black`}
                            onClick={() =>
                                setModalInfo({
                                    ...modalInfo,
                                    open: true,
                                    title: "Destroy Viewer",
                                    text: `Are you sure you wish to destroy the viewer?`,
                                    action: () => {
                                        doDestroy(route_params.site_id);
                                    },
                                })
                            }
                        >
                            take down viewer
                        </button>
                        <div
                            className={
                                "w-full mt-10 text-xs text-black h-96 border-2 border-gray-500 bg-gray-200 overflow-auto"
                            }
                        >
                            {Object.keys(realtime_store.realtimeMessages)
                                .sort()
                                .reverse()
                                .map((d, idx) => (
                                    <p key={d}>
                                        {timeAgo.format(new Date(realtime_store.realtimeMessages[d].date))}:{" "}
                                        {realtime_store.realtimeMessages[d].description}
                                    </p>
                                ))}
                        </div>
                    </div>
                    <div className={`flex-auto object-fill h-screen`}>
                        {realtime_store.siteviewerResponse?.ip && (
                            <iframe
                                className={`w-full h-full`}
                                title="realtime"
                                key={realtime_store.framekey}
                                src={getRealtimeUrl()}
                            ></iframe>
                        )}
                    </div>
                </div>
            </main>
            {modalInfo.open && <Confirm info={modalInfo} />}
        </>
    );
}
