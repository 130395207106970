import { createEvent, createStore } from "effector-logger";
import { ModificationError } from "../types/types";
import * as _ from "lodash";

import { v4 as uuidv4 } from "uuid";

import {
    ContentField,
    InputContentModify,
    InputContentFieldModify,
    App,
    ContentFieldType,
} from "billeci-private-sitebuilder-schema/dist/API";

export interface contentStore {
    holdingContent: Partial<InputContentModify>;
    holdingContentFields: Record<string, InputContentFieldModify>;
    section: string;
    contentTypeSection: ContentFieldType;
    errors: ModificationError[];
    mode: string;
    apps: App[];
    contentFields: Record<string, Record<string, ContentField>>;
    uploadStore: Record<string, string>;
}

export const emptyContentStore: contentStore = {
    holdingContent: {
        name: "",
    },
    holdingContentFields: {},
    section: "basics",
    contentTypeSection: ContentFieldType.TEXT,
    errors: [],
    apps: [],
    mode: "",
    contentFields: {},
    uploadStore: {},
};

export type ModifyContentDetails = {
    fieldName: string;
    fieldValue?: string;
    fieldValues?: string[];
};

export type ModifyHoldingContentFieldDetails = {
    fieldName: string;
    fieldValue: string;
};

export type ResetHoldingContentField = {
    fieldName: string;
};

export const updateContentStore = createEvent<Partial<contentStore>>();

export const resetContent = createEvent();

export const modifyContentDetails = createEvent<ModifyContentDetails>();
export const modifyHoldingContentFieldDetails = createEvent<ModifyHoldingContentFieldDetails>();
export const resetHoldingContentField = createEvent<ResetHoldingContentField>();

export default createStore<contentStore>(emptyContentStore)
    .on(updateContentStore, (state, s) => {
        return {
            ...state,
            ...s,
        };
    })
    .on(resetContent, (state) => {
        return {
            ...emptyContentStore,
        };
    })
    .on(resetHoldingContentField, (state, payload) => {
        if (state.holdingContent.appId) {
            let currentAppId = state.holdingContent.appId;
            let toCopy = _.clone(state.contentFields[currentAppId][payload.fieldName]);
            if (state.holdingContentFields[payload.fieldName]?.id) {
                toCopy.id = state.holdingContentFields[payload.fieldName]!.id!;
            } else {
                toCopy.id = uuidv4();
            }
            state.holdingContentFields[payload.fieldName] = toCopy;
        }
        return {
            ...state,
        };
    })
    .on(modifyHoldingContentFieldDetails, (state, payload) => {
        console.log(payload);
        var target = "value";
        if (payload.fieldName.endsWith("#css")) {
            target = "css";
            payload.fieldName = payload.fieldName.replace("#css", "");
        }
        state.holdingContentFields[payload.fieldName][target] = payload.fieldValue;
        return {
            ...state,
        };
    })
    .on(modifyContentDetails, (state, payload) => {
        var update: any = {};
        if (typeof payload.fieldValue != "undefined") {
            update[payload.fieldName] = payload.fieldValue;
        }
        if (typeof payload.fieldValues != "undefined") {
            update[payload.fieldName] = payload.fieldValues;
        }
        state.holdingContent = {
            ...state.holdingContent,
            ...update,
        };
        return {
            ...state,
        };
    });
