import { useState, useCallback, useEffect } from "react";
import { Link, useParams } from "react-router-dom";

import { API } from "aws-amplify";

import { Storage } from "aws-amplify";

import { v4 as uuidv4 } from "uuid";

import {
    App,
    Content,
    InputContentModify,
    InputContentFieldModify,
    ContentFieldType,
} from "billeci-private-sitebuilder-schema/dist/API";

import Confirm, { ModalInfo } from "../components/Confirm";
import Loader from "../components/Loader";

import { getContent, getApps, getApp } from "billeci-private-sitebuilder-schema/dist/graphql/queries";

import * as _ from "lodash";

import ContentStore, {
    updateContentStore,
    modifyContentDetails,
    modifyHoldingContentFieldDetails,
    resetHoldingContentField,
} from "../stores/ContentStore";
import { useStore } from "effector-react";

import Header from "../components/Header";

import { addContent, updateContent } from "billeci-private-sitebuilder-schema/dist/graphql/mutations";

import { notifyRealtime } from "../methods/notifyRealtime";

import { SketchPicker } from "react-color";

import { classNames, scrollToTop, menuSectionExistsInErrors, itemExistsInErrors } from "../utils/utils";
import { ModificationError, ToastMessage } from "../types/types";

import MediaPromise from "../components/MediaPromise";

function ContentsModify() {
    type routeParams = {
        content_id?: string;
    };

    const route_params: routeParams = useParams();

    const content_store = useStore(ContentStore);
    const [toastMsg, toastMsgSet] = useState<ToastMessage>();
    const [loading, loadingSet] = useState(true);
    const [modalInfo, setModalInfo] = useState<ModalInfo>({
        title: "Title",
        text: "Text",
        positive: "OK",
        negative: "Cancel",
        close: () => {
            setModalInfo({
                ...modalInfo,
                open: false,
            });
        },
        action: () => {},
        open: false,
    });

    const resetToDefault = (s: string) => {
        resetHoldingContentField({ fieldName: s });
    };

    const parseByKeyContentFields = (contentFields: InputContentFieldModify[]) => {
        var copyFields = {};
        for (let cf of contentFields ?? []) {
            copyFields[cf!.name!] = cf!;
        }
        return copyFields;
    };

    const getTypeCount = (t: string): string => {
        if (content_store.holdingContent.appId) {
            let c = Object.keys(content_store.holdingContentFields ?? []).filter(
                (q, idx) =>
                    content_store.holdingContentFields[q]!.type === t &&
                    content_store.contentFields[content_store.holdingContent.appId!][
                        content_store.holdingContentFields[q!]!.name ?? ""
                    ]
            );
            if (c && (c ?? []).length > 0) {
                return ` (${c.length})`;
            }
        }
        return "";
    };

    const pullContentFields = async (appId: string | undefined) => {
        if (appId && !content_store.contentFields[appId]) {
            /// query for fields associated with App
            var promises: any = {};
            var graphQlValues: any;

            promises["getApp"] = API.graphql({ query: getApp, variables: { id: appId } });

            try {
                /// query
                graphQlValues = await Promise.all([promises["getApp"]]);

                var rawApp = graphQlValues[0].data.getApp as App;

                var copyFields = _.cloneDeep(content_store.contentFields);
                copyFields[appId] = parseByKeyContentFields((rawApp.contentFields ?? []) as InputContentFieldModify[]);

                var holdingContentFields = _.cloneDeep(content_store.holdingContentFields);

                /// if they don't exist in HOLDING, set them from default (change ID)
                for (let cfKey of Object.keys(copyFields[appId])) {
                    if (!holdingContentFields[cfKey]) {
                        let toCopy = _.clone(copyFields[appId][cfKey]);
                        toCopy.id = uuidv4();
                        holdingContentFields[cfKey] = toCopy;
                    }
                }

                updateContentStore({
                    contentFields: copyFields,
                    holdingContentFields: holdingContentFields,
                });
            } catch (error) {
                console.log(error);
            }
        }
    };

    const getMediaItem = async (cfName) => {
        var url = await Storage.get(content_store.holdingContentFields[cfName]!.value!);
        return url;
    };

    const imageSize = function (e) {
        const promise = new Promise((resolve, reject) => {
            var reader = new FileReader();
            //Read the contents of Image File.
            reader.readAsDataURL(e.target.files[0]);
            reader.onload = function (e) {
                //Initiate the JavaScript Image object.
                var image = new Image();

                //Set the Base64 string return from FileReader as source.
                image.src = e.target!.result! as string;

                //Validate the File Height and Width.
                image.onload = function () {
                    var height = (this as HTMLImageElement).height;
                    var width = (this as HTMLImageElement).width;
                    resolve({ width, height });
                };
            };
        });

        return promise;
    };

    const onFileChoose = async (cfName, e, t: ContentFieldType) => {
        const file = e.target.files[0];
        const fileExt = file.type.split("/")[1];

        if (t === ContentFieldType.FAVICON) {
            var imageDimensions: any = await imageSize(e);
            if (
                imageDimensions["width"] !== imageDimensions["height"] ||
                Number(imageDimensions["width"]) < 260 ||
                Number(imageDimensions["height"]) < 260
            ) {
                alert("Image is not valid! Please upload a square image of at least 260x260!");
                return;
            }
        }

        try {
            var fileId = `${uuidv4()}.${fileExt}`;
            console.log(cfName);
            await Storage.put(fileId, file, {
                acl: "bucket-owner-full-control",
                metadata: { type: cfName },
                progressCallback(progress) {
                    var update: any = {};
                    update[cfName] = ((progress.loaded / progress.total) * 100).toFixed(2);
                    updateContentStore({
                        uploadStore: {
                            ...content_store.uploadStore,
                            ...update,
                        },
                    });
                },
            });
            handleContentFieldChange(cfName, fileId);
        } catch (err) {
            alert("Error!");
        }
    };

    const doRefreshIds = () => {
        if (content_store.holdingContent?.id) {
            var refreshContents = _.cloneDeep(content_store.holdingContent);
            /// top content ID
            refreshContents.id = uuidv4();
            /// ids for sub fields
            var refreshContentFields = _.cloneDeep(content_store.holdingContentFields);
            Object.keys(refreshContentFields).forEach((cf) => {
                refreshContentFields[cf].id = uuidv4();
            });
            updateContentStore({
                holdingContent: refreshContents,
                holdingContentFields: refreshContentFields,
            });
        }
    };

    useEffect(() => {
        const loadData = async () => {
            var promises: any = {};

            var mode: string = route_params.content_id ? "EDIT" : "ADD";
            updateContentStore({
                mode: mode,
                section: "basics",
            });
            var graphQlValues: any;

            promises["apps"] = API.graphql({ query: getApps });

            if (mode === "EDIT") {
                promises["content"] = API.graphql({ query: getContent, variables: { id: route_params.content_id } });
                try {
                    /// query
                    graphQlValues = await Promise.all([promises["content"], promises["apps"]]);

                    // get pulled Content
                    var rawContent = graphQlValues[0].data.getContent as Content;

                    // convert to InputContentModify
                    var inputContent = _.cloneDeep(rawContent) as unknown as InputContentModify;

                    var apps = graphQlValues[1].data.getApps as App[];
                    var appId = rawContent.app?.id!;
                    var myApp: App | undefined = undefined;

                    for (let a of apps) {
                        if (a.id === appId) {
                            myApp = a;
                            break;
                        }
                    }

                    /// set fields required by the selected app
                    var initialContentFields: any = {};
                    if (appId) {
                        inputContent.appId = appId;
                        initialContentFields[appId] = parseByKeyContentFields(
                            (myApp?.contentFields ?? []) as InputContentFieldModify[]
                        );
                    }

                    delete inputContent["app"];

                    var holdingContentFields: Record<string, InputContentFieldModify> = parseByKeyContentFields(
                        (inputContent.contentFields ?? []) as InputContentFieldModify[]
                    );

                    /// if they don't exist in HOLDING, set them from default (change ID)
                    for (let cfKey of Object.keys(initialContentFields[appId])) {
                        if (!holdingContentFields[cfKey]) {
                            let toCopy = _.clone(initialContentFields[appId][cfKey]);
                            toCopy.id = uuidv4();
                            holdingContentFields[cfKey] = toCopy;
                        }
                    }

                    updateContentStore({
                        apps: apps,
                        holdingContent: inputContent,
                        contentFields: initialContentFields,
                        holdingContentFields: holdingContentFields,
                    });
                    loadingSet(false);
                } catch (error) {
                    console.log(error);
                }
            } else {
                try {
                    /// query
                    graphQlValues = await Promise.all([promises["apps"]]);

                    updateContentStore({
                        apps: graphQlValues[0].data.getApps as App[],
                    });
                    loadingSet(false);
                } catch (error) {
                    console.log(error);
                }
            }
        };
        loadData();
    }, [route_params]);

    const handleContentFieldChange = (fieldName, fieldValue) => {
        modifyHoldingContentFieldDetails({ fieldName: fieldName, fieldValue: fieldValue });
    };

    const handleContentChange = (fieldName, fieldValue) => {
        modifyContentDetails({ fieldName: fieldName, fieldValue: fieldValue });
        pullContentFields(content_store.holdingContent?.appId);
    };

    const doValidateAndSave = useCallback(async () => {
        scrollToTop();
        /// check for various things
        //////
        var errors: ModificationError[] = [];

        /// check survey name filled in
        if (!content_store.holdingContent?.name) {
            errors.push({
                menuSection: ["basics"],
                item: `basics#name`,
                itemDescription: "Basic Section",
                message: `App Name missing`,
            });
        }

        if (!content_store.holdingContent?.appId) {
            errors.push({
                menuSection: ["basics"],
                item: `basics#appId`,
                itemDescription: "Basic Section",
                message: `App Selection missing`,
            });
        } else {
            // Object.keys(content_store.contentFields[content_store.holdingContent.appId!]).map((cf, cfIdx) => {
            //     if (!content_store.holdingContentFields[cf]?.value) {
            //         errors["content"] = "content";
            //         errors[`content#${cf}`] = "content value missing";
            //     }
            //     return true;
            // });
        }

        if (Object.keys(errors).length > 0) {
            toastMsgSet({ message: "Errors! Please Fix!", type: "ERROR" });
        }

        /// set errors in the state for visual representation
        updateContentStore({
            errors: errors,
        });

        // if we don't have any errors, time to submit it
        if (Object.keys(errors).length === 0) {
            /// send it
            console.log("send it");
            var promises: any = {};

            /// set holdingContent.contentFields from holdingContentFields
            let contentFields: InputContentFieldModify[] = [];
            for (let hcf of Object.keys(content_store.contentFields[content_store.holdingContent.appId!])) {
                let specCf = _.clone(
                    content_store.contentFields[content_store.holdingContent.appId!][hcf]
                ) as InputContentFieldModify;
                delete specCf["__typename"];
                specCf.value = content_store.holdingContentFields[hcf].value;
                specCf.css = content_store.holdingContentFields[hcf].css;
                specCf.id = content_store.holdingContentFields[hcf].id ?? uuidv4();
                contentFields.push(specCf);
            }
            content_store.holdingContent.contentFields = contentFields;

            let postContent = _.cloneDeep(content_store.holdingContent!);
            delete postContent["__typename"];

            promises["content"] = API.graphql({
                query: content_store.mode === "ADD" ? addContent : updateContent,
                variables: {
                    input: postContent,
                },
            });
            try {
                var add_result = await Promise.all([promises["content"]]);
                toastMsgSet({ message: "Saved Successfully!" });
                notifyRealtime("content", content_store.holdingContent!.id!);
                if (content_store.mode === "ADD") {
                    setTimeout(function () {
                        window.location.href = `/contents/${(add_result as any)[0].data.addContent.id}`;
                    }, 2000);
                }
            } catch (error) {
                console.log(error);
            }
        }
    }, [
        content_store.holdingContent,
        content_store.mode,
        content_store.contentFields,
        content_store.holdingContentFields,
    ]);

    return (
        <>
            <Header title={`${content_store.mode} Content`} toast={toastMsg} errors={content_store.errors} />
            <main>
                {loading ? (
                    <Loader />
                ) : (
                    <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
                        <div className="lg:grid lg:grid-cols-12 lg:gap-x-5">
                            <aside className="py-6 px-2 sm:px-6 lg:py-0 lg:px-0 lg:col-span-3">
                                <nav className="space-y-1">
                                    <button
                                        className={classNames(
                                            "w-full text-indigo-700 hover:text-indigo-700 hover:bg-white group rounded-md px-3 py-2 flex items-center text-sm font-medium",
                                            menuSectionExistsInErrors("basics", content_store.errors)
                                                ? "bg-red-100"
                                                : content_store.section === "basics"
                                                ? "bg-gray-100"
                                                : ""
                                        )}
                                        onClick={() => {
                                            updateContentStore({ section: "basics" });
                                        }}
                                        aria-current="page"
                                    >
                                        <svg
                                            className="text-indigo-500 group-hover:text-indigo-500 flex-shrink-0 -ml-1 mr-3 h-6 w-6"
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            stroke="currentColor"
                                            aria-hidden="true"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth="2"
                                                d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                                            />
                                        </svg>
                                        <span className="truncate">Basics</span>
                                    </button>
                                    <button
                                        className={classNames(
                                            "w-full text-indigo-700 hover:text-indigo-700 hover:bg-white group rounded-md px-3 py-2 flex items-center text-sm font-medium",
                                            content_store.errors["content"]
                                                ? "bg-red-100"
                                                : content_store.section === "content"
                                                ? "bg-gray-100"
                                                : ""
                                        )}
                                        onClick={() => {
                                            updateContentStore({ section: "content" });
                                        }}
                                        aria-current="page"
                                    >
                                        <svg
                                            className="text-indigo-500 group-hover:text-indigo-500 flex-shrink-0 -ml-1 mr-3 h-6 w-6"
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            stroke="currentColor"
                                            aria-hidden="true"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth="2"
                                                d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                                            />
                                        </svg>
                                        <span className="truncate">Required Content</span>
                                    </button>
                                </nav>

                                {content_store.holdingContent?.id && (
                                    <div className="w-full text-center">
                                        <Link
                                            to={`/contents/new`}
                                            className="mt-8 mx-auto w-1/2 bg-green-600 border border-transparent rounded-xl shadow-sm py-1 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                            onClick={() => {
                                                doRefreshIds();
                                            }}
                                        >
                                            (clone me)
                                        </Link>
                                    </div>
                                )}
                            </aside>

                            <div className="space-y-6 sm:px-6 lg:px-0 lg:col-span-9">
                                {content_store.section === "basics" && (
                                    <div className="shadow sm:rounded-md sm:overflow-hidden">
                                        <div className="bg-white py-6 px-4 space-y-6 sm:p-6">
                                            <div>
                                                <h3 className="text-lg leading-6 font-medium text-gray-900">
                                                    Basic content information
                                                </h3>
                                                <p className="mt-1 text-sm text-gray-500">
                                                    After this information is entered and the site is created, we will
                                                    have an ID to work from.
                                                </p>
                                            </div>

                                            <div
                                                className={classNames(
                                                    "grid grid-cols-3 gap-6",
                                                    itemExistsInErrors("basics#name", content_store.errors)
                                                        ? "bg-red-100"
                                                        : ""
                                                )}
                                            >
                                                <div className="col-span-3 sm:col-span-2">
                                                    <label
                                                        htmlFor="name"
                                                        className="block text-sm font-medium text-gray-700"
                                                    >
                                                        Content Name
                                                    </label>
                                                    <div className="mt-1 rounded-md shadow-sm flex">
                                                        <input
                                                            type="text"
                                                            name="name"
                                                            id="name"
                                                            value={content_store.holdingContent?.name}
                                                            onChange={(e) =>
                                                                handleContentChange(e.target.name!, e.target.value!)
                                                            }
                                                            placeholder=""
                                                            className="focus:ring-indigo-500 focus:border-indigo-500 flex-grow block w-full min-w-0 rounded-none rounded-r-md sm:text-sm border-gray-300"
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            <div
                                                className={classNames(
                                                    "grid grid-cols-3 gap-6",
                                                    itemExistsInErrors("basics#appId", content_store.errors)
                                                        ? "bg-red-100"
                                                        : ""
                                                )}
                                            >
                                                <div className="col-span-3 sm:col-span-2">
                                                    <label
                                                        htmlFor="name"
                                                        className="block text-sm font-medium text-gray-700"
                                                    >
                                                        App
                                                    </label>
                                                    <div className="mt-1 rounded-md shadow-sm">
                                                        <select
                                                            id="appId"
                                                            name="appId"
                                                            className="focus:ring-indigo-500 focus:border-indigo-500 flex-grow block w-full min-w-0 rounded-none rounded-r-md sm:text-sm border-gray-300"
                                                            value={content_store.holdingContent?.appId}
                                                            onChange={(e) =>
                                                                handleContentChange(e.target.name!, e.target.value!)
                                                            }
                                                        >
                                                            <option value=""></option>
                                                            {content_store.apps.map((a, idx) => (
                                                                <option key={idx} value={a.id}>
                                                                    {a.name}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
                                            <button
                                                type="button"
                                                onClick={() => doValidateAndSave()}
                                                className="bg-indigo-600 border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                            >
                                                Save
                                            </button>
                                        </div>
                                    </div>
                                )}
                                {content_store.section === "content" && (
                                    <>
                                        <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
                                            <button
                                                type="button"
                                                onClick={() => doValidateAndSave()}
                                                className="bg-indigo-600 border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                            >
                                                Save
                                            </button>
                                        </div>
                                        <div className="shadow sm:rounded-md sm:overflow-hidden">
                                            <div className="bg-white py-6 px-4 space-y-6 sm:p-6">
                                                <div>
                                                    <h3 className="text-lg leading-6 font-medium text-gray-900">
                                                        Required Content Information
                                                    </h3>
                                                    <p className="mt-1 text-sm text-gray-500">
                                                        Please satisfy all of the required content for selected App.
                                                    </p>
                                                </div>
                                                <div>
                                                    <div className="hidden sm:block">
                                                        <nav className="flex space-x-4" aria-label="Tabs">
                                                            {Object.keys(ContentFieldType).map((tab) => (
                                                                <button
                                                                    key={tab}
                                                                    className={classNames(
                                                                        tab === content_store.contentTypeSection
                                                                            ? "bg-gray-100 text-gray-700"
                                                                            : "text-gray-500 hover:text-gray-700",
                                                                        "px-3 py-2 font-medium text-sm rounded-md",
                                                                        content_store.errors[`content#${tab}`]
                                                                            ? "bg-red-100"
                                                                            : ""
                                                                    )}
                                                                    onClick={() => {
                                                                        updateContentStore({
                                                                            contentTypeSection: tab as ContentFieldType,
                                                                        });
                                                                    }}
                                                                    aria-current={
                                                                        tab === content_store.contentTypeSection
                                                                            ? "page"
                                                                            : undefined
                                                                    }
                                                                >
                                                                    {tab}
                                                                    {getTypeCount(tab)}
                                                                </button>
                                                            ))}
                                                        </nav>
                                                    </div>
                                                </div>
                                                {content_store.holdingContent?.appId &&
                                                    Object.keys(
                                                        content_store.contentFields[
                                                            content_store.holdingContent?.appId!
                                                        ]
                                                    )
                                                        .filter(
                                                            (cfKey, idx) =>
                                                                content_store.contentFields[
                                                                    content_store.holdingContent?.appId!
                                                                ][cfKey].type === content_store.contentTypeSection
                                                        )
                                                        .map((s, idx) => (
                                                            <div className="grid grid-cols-3 gap-6" key={idx}>
                                                                <div className="col-span-3 sm:col-span-2">
                                                                    <p>
                                                                        {s} -{" "}
                                                                        <b>
                                                                            {
                                                                                content_store.contentFields[
                                                                                    content_store.holdingContent?.appId!
                                                                                ][s].type
                                                                            }
                                                                        </b>{" "}
                                                                        <button onClick={() => resetToDefault(s)}>
                                                                            (reset)
                                                                        </button>
                                                                    </p>
                                                                    <>
                                                                        {["TEXTAREA", "LIST"].includes(
                                                                            content_store.contentFields[
                                                                                content_store.holdingContent?.appId!
                                                                            ][s].type
                                                                        ) && (
                                                                            <>
                                                                                <div className="mt-1 rounded-md shadow-sm flex">
                                                                                    <label
                                                                                        htmlFor={s}
                                                                                        className="block text-sm font-medium text-gray-700 pr-2"
                                                                                    >
                                                                                        Value:
                                                                                    </label>
                                                                                    <textarea
                                                                                        rows={5}
                                                                                        name={s}
                                                                                        id={s}
                                                                                        value={
                                                                                            content_store
                                                                                                .holdingContentFields[s]
                                                                                                ?.value ?? ""
                                                                                        }
                                                                                        onChange={(e) =>
                                                                                            handleContentFieldChange(
                                                                                                e.target.name!,
                                                                                                e.target.value!
                                                                                            )
                                                                                        }
                                                                                        placeholder="ex: Put your HTML here"
                                                                                        className="focus:ring-indigo-500 focus:border-indigo-500 flex-grow block w-full min-w-0 rounded-none rounded-r-md sm:text-sm border-gray-300"
                                                                                    />
                                                                                </div>
                                                                            </>
                                                                        )}
                                                                        {["COLOR"].includes(
                                                                            content_store.contentFields[
                                                                                content_store.holdingContent?.appId!
                                                                            ][s].type
                                                                        ) && (
                                                                            <div className="mt-1 rounded-md shadow-sm flex">
                                                                                <label
                                                                                    htmlFor={s}
                                                                                    className="block text-sm font-medium text-gray-700 pr-2"
                                                                                >
                                                                                    Value:
                                                                                </label>
                                                                                <SketchPicker
                                                                                    color={
                                                                                        content_store
                                                                                            .holdingContentFields[s]
                                                                                            ?.value &&
                                                                                        content_store
                                                                                            .holdingContentFields[s]
                                                                                            ?.value![0] === "{"
                                                                                            ? JSON.parse(
                                                                                                  content_store
                                                                                                      .holdingContentFields[
                                                                                                      s
                                                                                                  ]?.value!
                                                                                              )
                                                                                            : { r: 1, g: 1, b: 1, a: 1 }
                                                                                    }
                                                                                    onChange={(color) => {
                                                                                        console.log(color);
                                                                                        handleContentFieldChange(
                                                                                            s,
                                                                                            JSON.stringify(color.rgb)
                                                                                        );
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                        )}
                                                                        {["TEXT"].includes(
                                                                            content_store.contentFields[
                                                                                content_store.holdingContent?.appId!
                                                                            ][s].type
                                                                        ) && (
                                                                            <div className="mt-1 rounded-md shadow-sm flex">
                                                                                <label
                                                                                    htmlFor={s}
                                                                                    className="block text-sm font-medium text-gray-700 pr-2"
                                                                                >
                                                                                    Value:
                                                                                </label>
                                                                                <input
                                                                                    type="text"
                                                                                    name={s}
                                                                                    id={s}
                                                                                    value={
                                                                                        content_store
                                                                                            .holdingContentFields[s]
                                                                                            ?.value ?? ""
                                                                                    }
                                                                                    onChange={(e) =>
                                                                                        handleContentFieldChange(
                                                                                            e.target.name!,
                                                                                            e.target.value!
                                                                                        )
                                                                                    }
                                                                                    placeholder=""
                                                                                    className="focus:ring-indigo-500 focus:border-indigo-500 flex-grow block w-full min-w-0 rounded-none rounded-r-md sm:text-sm border-gray-300"
                                                                                />
                                                                            </div>
                                                                        )}
                                                                        {["FAVICON"].includes(
                                                                            content_store.contentFields[
                                                                                content_store.holdingContent?.appId!
                                                                            ][s].type
                                                                        ) && (
                                                                            <>
                                                                                {content_store.uploadStore[s] && (
                                                                                    <p>
                                                                                        {content_store.uploadStore[s]}%
                                                                                    </p>
                                                                                )}
                                                                                <div className="mt-1 rounded-md shadow-sm flex">
                                                                                    <label
                                                                                        htmlFor={s}
                                                                                        className="block text-sm font-medium text-gray-700 pr-2"
                                                                                    >
                                                                                        Value:
                                                                                    </label>
                                                                                    <input
                                                                                        type="file"
                                                                                        accept="image/png, image/jpeg, image/jpg"
                                                                                        onChange={(evt) =>
                                                                                            onFileChoose(
                                                                                                s,
                                                                                                evt,
                                                                                                content_store
                                                                                                    .contentFields[
                                                                                                    content_store
                                                                                                        .holdingContent
                                                                                                        ?.appId!
                                                                                                ][s].type
                                                                                            )
                                                                                        }
                                                                                    />
                                                                                    <p className="text-red-500">
                                                                                        Must be <b>JPG or PNG</b>,
                                                                                        <b>SQUARE DIMENSIONS!!</b> and
                                                                                        at least <b>260 x 260!!</b>
                                                                                    </p>
                                                                                </div>
                                                                            </>
                                                                        )}
                                                                        {["VIDEO"].includes(
                                                                            content_store.contentFields[
                                                                                content_store.holdingContent?.appId!
                                                                            ][s].type
                                                                        ) && (
                                                                            <>
                                                                                {content_store.uploadStore[s] && (
                                                                                    <p>
                                                                                        {content_store.uploadStore[s]}%
                                                                                    </p>
                                                                                )}
                                                                                <div className="mt-1 rounded-md shadow-sm flex">
                                                                                    <label
                                                                                        htmlFor={s}
                                                                                        className="block text-sm font-medium text-gray-700 pr-2"
                                                                                    >
                                                                                        Value:
                                                                                    </label>
                                                                                    <input
                                                                                        type="file"
                                                                                        accept="video/mp4"
                                                                                        onChange={(evt) =>
                                                                                            onFileChoose(
                                                                                                s,
                                                                                                evt,
                                                                                                content_store
                                                                                                    .contentFields[
                                                                                                    content_store
                                                                                                        .holdingContent
                                                                                                        ?.appId!
                                                                                                ][s].type
                                                                                            )
                                                                                        }
                                                                                    />
                                                                                    <p className="text-red-500">
                                                                                        Must be a <b>.MP4</b>{" "}
                                                                                        extension!!
                                                                                    </p>
                                                                                </div>
                                                                            </>
                                                                        )}
                                                                        {["IMAGE"].includes(
                                                                            content_store.contentFields[
                                                                                content_store.holdingContent?.appId!
                                                                            ][s].type
                                                                        ) && (
                                                                            <>
                                                                                {content_store.uploadStore[s] && (
                                                                                    <p>
                                                                                        {content_store.uploadStore[s]}%
                                                                                    </p>
                                                                                )}
                                                                                <div className="mt-1 rounded-md shadow-sm flex">
                                                                                    <label
                                                                                        htmlFor={s}
                                                                                        className="block text-sm font-medium text-gray-700 pr-2"
                                                                                    >
                                                                                        Value:
                                                                                    </label>
                                                                                    <input
                                                                                        type="file"
                                                                                        accept="image/png, image/gif, image/jpeg, image/jpg"
                                                                                        onChange={(evt) =>
                                                                                            onFileChoose(
                                                                                                s,
                                                                                                evt,
                                                                                                content_store
                                                                                                    .contentFields[
                                                                                                    content_store
                                                                                                        .holdingContent
                                                                                                        ?.appId!
                                                                                                ][s].type
                                                                                            )
                                                                                        }
                                                                                    />
                                                                                </div>
                                                                            </>
                                                                        )}
                                                                        {[
                                                                            "IMAGE",
                                                                            "CSS",
                                                                            "TEXT",
                                                                            "TEXTAREA",
                                                                            "LIST",
                                                                        ].includes(
                                                                            content_store.contentFields[
                                                                                content_store.holdingContent?.appId!
                                                                            ][s].type
                                                                        ) && (
                                                                            <div className="mt-1 rounded-md shadow-sm flex">
                                                                                <label
                                                                                    htmlFor={s}
                                                                                    className="block text-sm font-medium text-gray-700 pr-2"
                                                                                >
                                                                                    CSS:
                                                                                </label>
                                                                                <input
                                                                                    type="text"
                                                                                    name={`${s}#css`}
                                                                                    id={`${s}#css`}
                                                                                    value={
                                                                                        content_store
                                                                                            .holdingContentFields[s]
                                                                                            ?.css ?? ""
                                                                                    }
                                                                                    onChange={(e) =>
                                                                                        handleContentFieldChange(
                                                                                            e.target.name!,
                                                                                            e.target.value!
                                                                                        )
                                                                                    }
                                                                                    placeholder="(tailwind css)"
                                                                                    className="focus:ring-indigo-500 focus:border-indigo-500 flex-grow block w-full min-w-0 rounded-none rounded-r-md sm:text-sm border-gray-300"
                                                                                />
                                                                            </div>
                                                                        )}
                                                                        {["IMAGE", "VIDEO", "FAVICON"].includes(
                                                                            content_store.contentFields[
                                                                                content_store.holdingContent?.appId!
                                                                            ][s].type
                                                                        ) && (
                                                                            <>
                                                                                {content_store.holdingContentFields[s]
                                                                                    ?.value && (
                                                                                    <div>
                                                                                        Current File:{" "}
                                                                                        <MediaPromise
                                                                                            getter={async () =>
                                                                                                await getMediaItem(s)
                                                                                            }
                                                                                            mediaType={
                                                                                                content_store
                                                                                                    .contentFields[
                                                                                                    content_store
                                                                                                        .holdingContent
                                                                                                        ?.appId!
                                                                                                ][s].type
                                                                                            }
                                                                                        />
                                                                                        {["IMAGE"].includes(
                                                                                            content_store.contentFields[
                                                                                                content_store
                                                                                                    .holdingContent
                                                                                                    ?.appId!
                                                                                            ][s].type
                                                                                        ) && (
                                                                                            <>
                                                                                                <p>
                                                                                                    To include in
                                                                                                    content use:
                                                                                                </p>
                                                                                                <textarea
                                                                                                    className="w-full"
                                                                                                    readOnly={true}
                                                                                                    defaultValue={`<img class="" alt="ALT_HERE" src="%PUBLIC_URL%/media/${content_store.holdingContentFields[s]?.value}" />`}
                                                                                                ></textarea>
                                                                                            </>
                                                                                        )}
                                                                                    </div>
                                                                                )}
                                                                            </>
                                                                        )}
                                                                    </>
                                                                </div>
                                                            </div>
                                                        ))}
                                            </div>
                                            <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
                                                <button
                                                    type="button"
                                                    onClick={() => doValidateAndSave()}
                                                    className="bg-indigo-600 border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                                >
                                                    Save
                                                </button>
                                            </div>
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                )}
            </main>
            {modalInfo.open && <Confirm info={modalInfo} />}
        </>
    );
}

export default ContentsModify;
