import { useState } from "react";

import { Domain } from "billeci-private-sitebuilder-schema/dist/API";

import { API } from "aws-amplify";

import {
    renewRegistrationDomain,
    disableRegistrationDomain,
} from "billeci-private-sitebuilder-schema/dist/graphql/mutations";

const doRenew = async (name) => {
    await API.graphql({
        query: renewRegistrationDomain,
        variables: {
            name: name,
        },
    });
};

const doDisable = async (name) => {
    await API.graphql({
        query: disableRegistrationDomain,
        variables: {
            name: name,
        },
    });
};

export default function DomainRenewActivity(props: {
    domain: Domain;
    index: number;
    callback: (i: number, s: boolean) => void;
}) {
    const [load, loadSet] = useState(false);
    return (
        <>
            {props.domain.renewActive === true && (
                <>
                    <span
                        onClick={async () => {
                            loadSet(true);
                            try {
                                await doDisable(props.domain.name);
                                props.callback(props.index, false);
                            } catch (err) {
                            } finally {
                                loadSet(false);
                            }
                        }}
                        className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800 cursor-pointer"
                    >
                        Active
                    </span>
                    {load && (
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="w-6 h-6"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99"
                            />
                        </svg>
                    )}
                </>
            )}
            {props.domain.renewActive === false && (
                <>
                    <span
                        onClick={async () => {
                            loadSet(true);
                            try {
                                await doRenew(props.domain.name);
                                props.callback(props.index, true);
                            } catch (err) {
                            } finally {
                                loadSet(false);
                            }
                        }}
                        className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800 cursor-pointer"
                    >
                        Inactive
                    </span>
                    {load && (
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="w-6 h-6"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99"
                            />
                        </svg>
                    )}
                </>
            )}
            {props.domain.renewActive === null && (
                <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-gray-100 text-gray-800">
                    Not at R53
                </span>
            )}
        </>
    );
}
