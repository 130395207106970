/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    aws_project_region: "us-west-2",
    aws_appsync_graphqlEndpoint: "https://mogmj72hgzeczoocfupq2fhsu4.appsync-api.us-west-2.amazonaws.com/graphql",
    aws_appsync_region: "us-west-2",
    aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS",
    aws_cognito_region: "us-west-2",
    aws_user_pools_id: "us-west-2_CMsnYROzF",
    aws_user_pools_web_client_id: "6jp7bbcum0gq66lol0ahvopve",
    aws_cognito_identity_pool_id: "us-west-2:f6b7fafc-ce18-4575-ad0c-0d414ccaeb8f",
    Auth: {
        region: "us-west-2",
        userPoolId: "us-west-2_CMsnYROzF",
        userPoolWebClientId: "6jp7bbcum0gq66lol0ahvopve",
        identityPoolId: "us-west-2:f6b7fafc-ce18-4575-ad0c-0d414ccaeb8f",
    },
    Storage: {
        AWSS3: {
            bucket: "billeci-billeci-artifacts-prod",
            region: "us-west-2",
        },
    },
    mandatorySignIn: true,
    oauth: {
        domain: "auth.billeci.com",
        scope: ["email", "profile", "openid"],
        redirectSignIn: "https://sitebuilder.billeci.com/",
        redirectSignOut: "https://sitebuilder.billeci.com/",
        responseType: "code",
    },
};

export default awsmobile;
