import Header from "../components/Header";

import React, { useState } from "react";

import { Link } from "react-router-dom";

import { getDomainSuggestions } from "billeci-private-sitebuilder-schema/dist/graphql/queries";
import { registerDomain } from "billeci-private-sitebuilder-schema/dist/graphql/mutations";

import { Redirect } from "react-router-dom";

import { API } from "aws-amplify";

import { Domain } from "billeci-private-sitebuilder-schema/dist/API";

import * as _ from "lodash";

function DomainsNew() {
    const [domains, domainsSet] = useState<Domain[] | null>(null);
    const [domain, domainSet] = useState<Domain | null>(null);
    const [submitted, submittedSet] = useState(false);

    const fetchSearchData = (d: string) => {
        const loadData = async (d: string) => {
            const response: any = await API.graphql({ query: getDomainSuggestions, variables: { name: d } });
            domainSet(response.data.getDomainSuggestions.domain);
            domainsSet(response.data.getDomainSuggestions.suggestions);
        };
        loadData(d);
    };

    const [debouncedCallApi] = useState(() => _.debounce(fetchSearchData, 600));

    const searchDomainChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        debouncedCallApi(event.target.value);
    };

    const doRegisterDomain = (d: string | undefined | null) => {
        const submitRegistration = async (d: string) => {
            try {
                await API.graphql({ query: registerDomain, variables: { name: d } });
                submittedSet(true);
            } catch (e) {
                alert(JSON.stringify(e));
            }
        };
        if (d) {
            submitRegistration(d);
        }
    };

    return (
        <>
            <Header title="Register Domain" />
            <main>
                <div className="max-w-7xl mx-auto px-4 py-12 sm:px-6 lg:py-8 lg:px-8">
                    <div className="px-6 py-6 bg-indigo-700 rounded-lg md:py-12 md:px-12 lg:py-16 lg:px-16 xl:flex xl:items-center">
                        <div className="xl:w-0 xl:flex-1">
                            <h2 className="text-2xl font-extrabold tracking-tight text-white sm:text-3xl">
                                Need a new domain name?
                            </h2>
                            <p className="mt-3 max-w-3xl text-lg leading-6 text-indigo-200">
                                Let's see what Route53 has available..
                            </p>
                        </div>
                        <div className="mt-8 sm:w-full sm:max-w-md xl:mt-0 xl:ml-8">
                            <input
                                id="domainname"
                                name="domainname"
                                type="text"
                                required
                                className="w-full border-white px-5 py-3 placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-indigo-700 focus:ring-white rounded-md"
                                placeholder="search for a domain"
                                onChange={searchDomainChanged}
                            />
                            <p className="mt-3 text-sm text-indigo-200">
                                See the list of domains we already own
                                <Link to={`/domains`} className="pl-1 text-white font-medium underline">
                                    here
                                </Link>
                                .
                            </p>
                        </div>
                    </div>
                </div>
                <div className="max-w-7xl mx-auto py-3 sm:px-6 lg:px-8">
                    <div className="flex flex-col">
                        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                            <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                                <p className="p-3 text-gray-600">Your Search Results</p>
                                <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                                    <table className="min-w-full divide-y divide-gray-200">
                                        <thead className="bg-gray-50">
                                            <tr>
                                                <th
                                                    scope="col"
                                                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                                >
                                                    Name
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                                >
                                                    Status
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                                >
                                                    Price
                                                </th>
                                                <th scope="col" className="relative px-6 py-3">
                                                    <span className="sr-only">Register</span>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody className="bg-white divide-y divide-gray-200">
                                            {domain && (
                                                <tr>
                                                    <td className="px-6 py-4 whitespace-nowrap">
                                                        <div className="text-sm text-gray-900">{domain?.name}</div>
                                                    </td>
                                                    <td className="px-6 py-4 whitespace-nowrap">
                                                        {domain.available ? (
                                                            <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                                                                Available
                                                            </span>
                                                        ) : (
                                                            <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800">
                                                                Unavailable
                                                            </span>
                                                        )}
                                                    </td>
                                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                                        ${domain?.price}
                                                    </td>
                                                    <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                                                        {domain?.available ? (
                                                            <button
                                                                onClick={(e) => {
                                                                    if (
                                                                        window.confirm(
                                                                            `Are you sure you wish to register ${domain?.name}?`
                                                                        )
                                                                    )
                                                                        doRegisterDomain(domain?.name);
                                                                }}
                                                                className="text-indigo-600 hover:text-indigo-900"
                                                            >
                                                                Register
                                                            </button>
                                                        ) : (
                                                            <p className="text-gray-300">Register</p>
                                                        )}
                                                    </td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="max-w-7xl mx-auto py-3 sm:px-6 lg:px-8">
                    <div className="flex flex-col">
                        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                            <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                                <p className="p-3 text-gray-600">Suggestions</p>
                                <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                                    <table className="min-w-full divide-y divide-gray-200">
                                        <thead className="bg-gray-50">
                                            <tr>
                                                <th
                                                    scope="col"
                                                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                                >
                                                    Name
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                                >
                                                    Status
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                                >
                                                    Price
                                                </th>
                                                <th scope="col" className="relative px-6 py-3">
                                                    <span className="sr-only">Register</span>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody className="bg-white divide-y divide-gray-200">
                                            {domains?.map((domain: any, index: number) => (
                                                <tr key={index}>
                                                    <td className="px-6 py-4 whitespace-nowrap">
                                                        <div className="text-sm text-gray-900">{domain?.name}</div>
                                                    </td>
                                                    <td className="px-6 py-4 whitespace-nowrap">
                                                        <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                                                            Available
                                                        </span>
                                                    </td>
                                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                                        ${domain?.price}
                                                    </td>
                                                    <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                                                        <button
                                                            onClick={(e) => {
                                                                if (
                                                                    window.confirm(
                                                                        `Are you sure you wish to register ${domain?.name}?`
                                                                    )
                                                                )
                                                                    doRegisterDomain(domain?.name);
                                                            }}
                                                            className="text-indigo-600 hover:text-indigo-900"
                                                        >
                                                            Register
                                                        </button>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
            {submitted && <Redirect to={`/domains`} />}
        </>
    );
}

export default DomainsNew;
