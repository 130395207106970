import { createEvent, createStore } from "effector-logger";

import { RealtimeEvent, SiteViewerResponse, Site } from "billeci-private-sitebuilder-schema/dist/API";

export interface realtimeStore {
    site: Partial<Site>;
    siteviewerResponse: Partial<SiteViewerResponse>;
    realtimeMessages: Record<string, RealtimeEvent>;
    framekey: string;
}

const emptyRealtimeStore: realtimeStore = {
    site: {},
    siteviewerResponse: {},
    realtimeMessages: {},
    framekey: "init",
};

export const updateRealtimeStore = createEvent<Partial<realtimeStore>>();

export const addRealtimeMessage = createEvent<RealtimeEvent>();

export default createStore<realtimeStore>(emptyRealtimeStore)
    .on(updateRealtimeStore, (state, s) => {
        return {
            ...state,
            ...s,
        };
    })
    .on(addRealtimeMessage, (state, m) => {
        console.log("inocming message");
        console.log(m);
        if (!state.siteviewerResponse.siteId || String(m.siteId) === String(state.siteviewerResponse.siteId)) {
            console.log("adding msg");
            state.realtimeMessages[String(m.date)] = m;
        }
        return {
            ...state,
        };
    });
