import { createEvent, createStore } from "effector-logger";
import { ModificationError } from "../types/types";
import { InputFirmModify } from "billeci-private-sitebuilder-schema/dist/API";

export interface firmStore {
    holdingFirm?: Partial<InputFirmModify>;
    section: string;
    errors: ModificationError[];
    mode: string;
}

export const emptyFirmStore: firmStore = {
    holdingFirm: {
        name: "",
    },
    section: "basics",
    errors: [],
    mode: "",
};

export type ModifyFirmDetails = {
    fieldName: string;
    fieldValue?: string;
    fieldValues?: string[];
};

export const resetFirm = createEvent();
export const updateFirmStore = createEvent<Partial<firmStore>>();

export const modifyFirmDetails = createEvent<ModifyFirmDetails>();

export default createStore<firmStore>(emptyFirmStore)
    .on(updateFirmStore, (state, s) => {
        return {
            ...state,
            ...s,
        };
    })
    .on(resetFirm, (state) => {
        return {
            ...emptyFirmStore,
        };
    })
    .on(modifyFirmDetails, (state, payload) => {
        var update: any = {};
        if (typeof payload.fieldValue != "undefined") {
            update[payload.fieldName] = payload.fieldValue;
        }
        if (typeof payload.fieldValues != "undefined") {
            update[payload.fieldName] = payload.fieldValues;
        }
        state.holdingFirm = {
            ...state.holdingFirm,
            ...update,
        };
        return {
            ...state,
        };
    });
