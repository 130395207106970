import { useState, useCallback, useEffect, useMemo } from "react";
import { Link, useParams } from "react-router-dom";

import { API } from "aws-amplify";

import { v4 as uuidv4 } from "uuid";

import {
    Survey,
    InputSurveyModify,
    InputQuestionModify,
    Option,
    QuestionType,
    OptionAction,
    App,
    Source,
    TortQualifySurvey,
} from "billeci-private-sitebuilder-schema/dist/API";

import Confirm, { ModalInfo } from "../components/Confirm";
import Loader from "../components/Loader";

import { getApps, getSurvey, getSources } from "billeci-private-sitebuilder-schema/dist/graphql/queries";

import * as _ from "lodash";

import SurveyStore, {
    updateSurveyStore,
    modifySurveyDetails,
    modifyQuestionDetails,
    addQuestion,
    removeQuestion,
    modifyOptionDetails,
    addOption,
    removeOption,
    modifyOptionDetailsMultiString,
    addOptionDetailsMultiString,
    removeOptionDetailsMultiString,
} from "../stores/SurveyStore";
import { useStore } from "effector-react";

import Header from "../components/Header";

import { notifyRealtime } from "../methods/notifyRealtime";

import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { addSurvey, updateSurvey } from "billeci-private-sitebuilder-schema/dist/graphql/mutations";

import { classNames, scrollToTop, menuSectionExistsInErrors, itemExistsInErrors } from "../utils/utils";
import { ToastMessage, ModificationError } from "../types/types";

import { getTortQualifySurveysShort } from "../customGql/queries";

const reorder = (list: Iterable<unknown> | ArrayLike<unknown>, startIndex: number, endIndex: number) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
};

function SurveysModify() {
    type routeParams = {
        survey_id?: string;
    };

    const route_params: routeParams = useParams();

    const survey_store = useStore(SurveyStore);
    const [toastMsg, toastMsgSet] = useState<ToastMessage>();
    const [loading, loadingSet] = useState(true);
    const [modalInfo, setModalInfo] = useState<ModalInfo>({
        title: "Title",
        text: "Text",
        positive: "OK",
        negative: "Cancel",
        close: () => {
            setModalInfo({
                ...modalInfo,
                open: false,
            });
        },
        action: () => {},
        open: false,
    });

    const doRefreshIds = () => {
        if (survey_store.holdingSurvey?.id) {
            var refreshSurvey = _.cloneDeep(survey_store.holdingSurvey);
            /// top survey ID
            refreshSurvey.id = uuidv4();
            /// each question
            refreshSurvey?.questions?.forEach((q) => {
                q!.id = uuidv4();
                q?.options?.forEach((o) => {
                    o!.id = uuidv4();
                });
            });
            updateSurveyStore({
                holdingSurvey: refreshSurvey,
            });
        }
    };

    useEffect(() => {
        const loadData = async () => {
            var promises: any = {};

            promises["apps"] = API.graphql({ query: getApps });
            promises["sources"] = API.graphql({ query: getSources });
            promises["tqsurveys"] = API.graphql({ query: getTortQualifySurveysShort });

            var mode: string = route_params.survey_id ? "EDIT" : "ADD";

            updateSurveyStore({
                mode: mode,
                section: "basics",
            });
            var graphQlValues: any;

            if (mode === "EDIT") {
                promises["survey"] = API.graphql({ query: getSurvey, variables: { id: route_params.survey_id } });
                try {
                    graphQlValues = await Promise.all([
                        promises["apps"],
                        promises["sources"],
                        promises["survey"],
                        promises["tqsurveys"],
                    ]);

                    // get pulled Survey
                    var rawSurvey = graphQlValues[2].data.getSurvey as Survey;

                    // convert to InputSurveyModify
                    var inputSurvey = _.cloneDeep(rawSurvey) as unknown as InputSurveyModify;

                    // make needed changes
                    inputSurvey.appId = rawSurvey.app?.id ?? "-1";
                    delete inputSurvey["app"];

                    updateSurveyStore({
                        apps: graphQlValues[0].data.getApps as App[],
                        sources: graphQlValues[1].data.getSources as Source[],
                        tqsurveys: graphQlValues[3].data.getTortQualifySurveys as Partial<TortQualifySurvey>[],
                        holdingSurvey: inputSurvey,
                    });
                    loadingSet(false);
                } catch (error) {
                    console.log(error);
                }
            } else {
                try {
                    graphQlValues = await Promise.all([promises["apps"], promises["sources"], promises["tqsurveys"]]);
                    updateSurveyStore({
                        apps: graphQlValues[0].data.getApps as App[],
                        sources: graphQlValues[1].data.getSources as Source[],
                        tqsurveys: graphQlValues[2].data.getTortQualifySurveys as Partial<TortQualifySurvey>[],
                    });
                    loadingSet(false);
                } catch (error) {
                    console.log(error);
                }
            }
        };
        loadData();
    }, [route_params]);

    const doRemoveQuestion = (questionIdx) => {
        if (survey_store.holdingSurvey?.questions![questionIdx]?.id === survey_store.questionEditId) {
            updateSurveyStore({ section: "basics" });
        }
        removeQuestion(questionIdx);
    };

    const addQuestionOptionMultiStringChange = (qIdx, oIdx, f) => {
        addOptionDetailsMultiString({
            qIdx: qIdx,
            oIdx: oIdx,
            field: f,
        });
    };

    const removeQuestionOptionMultiStringChange = (qIdx, oIdx, iIdx, f) => {
        removeOptionDetailsMultiString({
            qIdx: qIdx,
            oIdx: oIdx,
            iIdx: iIdx,
            field: f,
        });
    };

    const handleQuestionOptionMultiStringChange = (qIdx, oIdx, iIdx, fieldName, fieldValue, f) => {
        modifyOptionDetailsMultiString({
            fieldName: fieldName,
            fieldValue: fieldValue,
            qIdx: qIdx,
            iIdx: iIdx,
            oIdx: oIdx,
            field: f,
        });
    };

    const handleSurveyChange = (fieldName, fieldValue) => {
        modifySurveyDetails({ fieldName: fieldName, fieldValue: fieldValue });
    };

    const handleQuestionChange = (idx, fieldName, fieldValue) => {
        modifyQuestionDetails({ idx: idx, fieldName: fieldName, fieldValue: fieldValue });
    };

    const handleQuestionOptionChange = (qIdx, idx, fieldName, fieldValue) => {
        modifyOptionDetails({ qIdx: qIdx, idx: idx, fieldName: fieldName, fieldValue: fieldValue });
    };

    const questionIdx = useMemo(() => {
        var qIdx = -1;
        if (survey_store.holdingSurvey?.questions) {
            for (let q in survey_store?.holdingSurvey.questions!) {
                if (survey_store?.holdingSurvey.questions[q]!.id === survey_store.questionEditId) {
                    qIdx = Number(q);
                }
            }
        }
        return qIdx;
    }, [survey_store.holdingSurvey?.questions, survey_store.questionEditId]);

    const onDragEnd = (result: any) => {
        if (!result.destination) {
            return;
        }
        if (result.source.droppableId === "questionList" && result.destination.droppableId === "questionList") {
            const items = reorder(
                survey_store.holdingSurvey!.questions!,
                result.source.index,
                result.destination.index
            );
            updateSurveyStore({
                holdingSurvey: {
                    ...survey_store.holdingSurvey!,
                    questions: items as InputQuestionModify[],
                },
            });
        }
        if (result.source.droppableId === "optionList" && result.destination.droppableId === "optionList") {
            var itemSplit = result.draggableId.split("#");
            var qIdx = itemSplit[1];
            const itemsOptions = reorder(
                survey_store.holdingSurvey!.questions![qIdx]!.options!,
                result.source.index,
                result.destination.index
            ) as Option[];
            var holdingSurvey = _.cloneDeep(survey_store.holdingSurvey!);
            holdingSurvey!.questions![qIdx]!.options = itemsOptions;
            updateSurveyStore({
                holdingSurvey: {
                    ...holdingSurvey!,
                },
            });
        }
    };

    const getApp = useCallback((): App | undefined => {
        var appId = survey_store.holdingSurvey?.appId;
        var theApp: App | undefined = undefined;
        if (appId) {
            survey_store.apps.map((a, idx) => {
                if (a.id === appId) {
                    theApp = a;
                }
                return true;
            });
        }
        return theApp;
    }, [survey_store.apps, survey_store.holdingSurvey?.appId]);

    const doValidateAndSave = useCallback(async () => {
        scrollToTop();

        /// check for various things
        //////
        var errors: ModificationError[] = [];

        /// check survey name filled in
        if (!survey_store.holdingSurvey?.name) {
            errors.push({
                menuSection: ["basics"],
                item: `basics#name`,
                itemDescription: "Basic Section",
                message: `Survey Name missing`,
            });
        }

        /// check app is selected
        if (!survey_store.holdingSurvey?.appId) {
            errors.push({
                menuSection: ["basics"],
                item: `basics#appId`,
                itemDescription: "Basic Section",
                message: `App Selection missing`,
            });
        }

        if (!survey_store.holdingSurvey?.questions || survey_store.holdingSurvey?.questions.length === 0) {
            errors.push({
                menuSection: ["questions"],
                itemDescription: "Questions",
                message: `At least one question needed`,
            });
        }

        var qualifiesFor: string[] = [];

        /// check that each question is filled in properly
        survey_store.holdingSurvey?.questions?.map((q, qIdx) => {
            if (!q?.description || q?.description === "") {
                errors.push({
                    menuSection: ["questions", `questions#${qIdx}`],
                    item: `questions#${qIdx}#description`,
                    itemDescription: `Question ${qIdx + 1}`,
                    message: `Question text missing`,
                });
            }
            if (!q?.token || q?.token === "") {
                errors.push({
                    menuSection: ["questions", `questions#${qIdx}`],
                    item: `questions#${qIdx}#token`,
                    itemDescription: `Question ${qIdx + 1}`,
                    message: `Question token missing`,
                });
            }
            if (!q?.type) {
                errors.push({
                    menuSection: ["questions", `questions#${qIdx}`],
                    item: `questions#${qIdx}#type`,
                    itemDescription: `Question ${qIdx + 1}`,
                    message: `Question type missing`,
                });
            }
            if (!q?.options || q?.options.length < 2) {
                errors.push({
                    menuSection: ["questions", `questions#${qIdx}`],
                    itemDescription: `Question ${qIdx + 1}`,
                    message: `At least 2 options needed`,
                });
            }
            q?.options?.map((o, idx) => {
                if (!o?.value || o?.value === "") {
                    errors.push({
                        menuSection: ["questions", `questions#${qIdx}`],
                        item: `questions#${qIdx}#options#${idx}#value`,
                        itemDescription: `Question ${qIdx + 1}, Option ${idx + 1}`,
                        message: `option value missing`,
                    });
                }
                if (
                    o?.action &&
                    ["EDUCATE", "DISQUALIFY"].includes(o?.action) &&
                    (!o?.actionText || o?.actionText === "")
                ) {
                    errors.push({
                        menuSection: ["questions", `questions#${qIdx}`],
                        item: `questions#${qIdx}#options#${idx}#actionText`,
                        itemDescription: `Question ${qIdx + 1}, Option ${idx + 1}`,
                        message: `${o?.action} text missing missing`,
                    });
                }
                if (
                    o?.action &&
                    ["QUALIFY"].includes(o?.action) &&
                    (!o?.qualifySource || o?.qualifySource.length === 0)
                ) {
                    errors.push({
                        menuSection: ["questions", `questions#${qIdx}`],
                        item: `questions#${qIdx}#options#${idx}#action`,
                        itemDescription: `Question ${qIdx + 1}, Option ${idx + 1}`,
                        message: `QUALIFY requires at least one option`,
                    });
                }
                if (
                    o?.action &&
                    ["DISQUALIFYFOR"].includes(o?.action) &&
                    (!o?.disqualifySource || o?.disqualifySource.length === 0)
                ) {
                    errors.push({
                        menuSection: ["questions", `questions#${qIdx}`],
                        item: `questions#${qIdx}#options#${idx}#action`,
                        itemDescription: `Question ${qIdx + 1}, Option ${idx + 1}`,
                        message: `DISQUALIFYFOR requires at least one option`,
                    });
                }
                // add the qualifying action to the list of all qualifying actions for error check
                if (o?.action === "QUALIFY" && o?.qualifySource) {
                    for (let s of o?.qualifySource) {
                        if (s) {
                            qualifiesFor.push(s);
                        }
                    }
                }
                if (o?.action === "TORTQUALIFY" && o?.tortQualifySurveyIds) {
                    for (let tqId of o?.tortQualifySurveyIds) {
                        if (tqId) {
                            qualifiesFor.push(tqId);
                        }
                    }
                }
                return true;
            });
            return true;
        });
        if (qualifiesFor.length === 0) {
            errors.push({
                menuSection: ["questions"],
                itemDescription: `Survey`,
                message: `No question options QUALIFY the survey for anything`,
            });
        }
        if (
            getApp()?.questionCount &&
            survey_store.holdingSurvey?.questions &&
            survey_store.holdingSurvey?.questions.length !== getApp()!.questionCount
        ) {
            /// check that the number of questions set == app requirment if there is one
            errors.push({
                menuSection: ["questions"],
                item: `questions#mismatchQuestions`,
                itemDescription: "Questions",
                message: `Number of questions does not match APP requirement`,
            });
        }

        if (errors.length > 0) {
            toastMsgSet({ message: "Errors! Please Fix!", type: "ERROR" });
        }

        /// set errors in the state for visual representation
        updateSurveyStore({
            errors: errors,
        });

        // if we don't have any errors, time to submit it
        if (errors.length === 0) {
            /// send it
            console.log("send it");
            var promises: any = {};

            let postSurvey = _.cloneDeep(survey_store.holdingSurvey!);
            delete postSurvey["__typename"];

            if (postSurvey?.questions) {
                postSurvey.questions = postSurvey?.questions?.map((e) => {
                    delete e!["__typename"];
                    if (e!.options) {
                        e!.options = e?.options.map((q) => {
                            delete q!["__typename"];
                            return q;
                        });
                    }
                    return e;
                });
            }

            if (postSurvey?.questions) {
                postSurvey.questions = postSurvey?.questions?.map((e) => {
                    delete e!["__typename"];
                    return e;
                });
            }

            promises["survey"] = API.graphql({
                query: survey_store.mode === "ADD" ? addSurvey : updateSurvey,
                variables: {
                    input: postSurvey,
                },
            });
            try {
                var add_result = await Promise.all([promises["survey"]]);
                toastMsgSet({ message: "Saved Successfully!" });
                notifyRealtime("survey", survey_store.holdingSurvey!.id!);
                if (survey_store.mode === "ADD") {
                    setTimeout(function () {
                        window.location.href = `/surveys/${(add_result as any)[0].data.addSurvey.id}`;
                    }, 2000);
                }
            } catch (error) {
                console.log(error);
            }
        }
    }, [survey_store.holdingSurvey, survey_store.mode, getApp]);

    return (
        <>
            <Header title={`${survey_store.mode} Survey`} toast={toastMsg} errors={survey_store.errors} />
            <main>
                {loading ? (
                    <Loader />
                ) : (
                    <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
                        <div className="lg:grid lg:grid-cols-12 lg:gap-x-5">
                            <aside className="py-6 px-2 sm:px-6 lg:py-0 lg:px-0 lg:col-span-3">
                                <nav className="space-y-1">
                                    <button
                                        className={classNames(
                                            "w-full text-indigo-700 hover:text-indigo-700 hover:bg-white group rounded-md px-3 py-2 flex items-center text-sm font-medium",
                                            menuSectionExistsInErrors("basics", survey_store.errors)
                                                ? "bg-red-100"
                                                : survey_store.section === "basics"
                                                ? "bg-gray-100"
                                                : ""
                                        )}
                                        onClick={() => {
                                            updateSurveyStore({ section: "basics" });
                                        }}
                                        aria-current="page"
                                    >
                                        <svg
                                            className="text-indigo-500 group-hover:text-indigo-500 flex-shrink-0 -ml-1 mr-3 h-6 w-6"
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 2444 24"
                                            stroke="currentColor"
                                            aria-hidden="true"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth="2"
                                                d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                                            />
                                        </svg>
                                        <span className="truncate">Basics</span>
                                    </button>
                                    <button
                                        className={classNames(
                                            "w-full text-indigo-700 hover:text-indigo-700 hover:bg-white group rounded-md px-3 py-2 flex items-center text-sm font-medium",
                                            menuSectionExistsInErrors("questions", survey_store.errors)
                                                ? "bg-red-100"
                                                : survey_store.section === "questions"
                                                ? "bg-gray-100"
                                                : ""
                                        )}
                                        onClick={() => {
                                            updateSurveyStore({ section: "questions" });
                                        }}
                                        aria-current="page"
                                    >
                                        <svg
                                            className="text-indigo-500 group-hover:text-indigo-500 flex-shrink-0 -ml-1 mr-3 h-6 w-6"
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            stroke="currentColor"
                                            aria-hidden="true"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth="2"
                                                d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                                            />
                                        </svg>
                                        <span className="truncate">
                                            Questions{" "}
                                            {getApp() && getApp()?.questionCount ? (
                                                <span>
                                                    [
                                                    {survey_store.holdingSurvey?.questions
                                                        ? survey_store.holdingSurvey?.questions.length
                                                        : "0"}{" "}
                                                    / {getApp()!.questionCount!}]
                                                </span>
                                            ) : (
                                                <span>[{survey_store?.holdingSurvey?.questions?.length ?? "0"}]</span>
                                            )}
                                            <span
                                                className="float-none ml-3 bg-blue-500 hover:bg-blue-700 text-white text-s font-bold py-1 px-3 mb-1 "
                                                onClick={() => addQuestion()}
                                            >
                                                +
                                            </span>
                                        </span>
                                    </button>

                                    <DragDropContext onDragEnd={onDragEnd}>
                                        <Droppable droppableId="questionList">
                                            {(provided, snapshot) => (
                                                <div {...provided.droppableProps} ref={provided.innerRef}>
                                                    {survey_store.holdingSurvey?.questions?.map((q, idx) => (
                                                        <Draggable
                                                            key={idx}
                                                            draggableId={`Question#${idx}}`}
                                                            index={idx}
                                                        >
                                                            {(provided, snapshot) => (
                                                                <div
                                                                    key={idx}
                                                                    className={classNames("flex")}
                                                                    ref={provided.innerRef}
                                                                    {...provided.draggableProps}
                                                                    {...provided.dragHandleProps}
                                                                >
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        className="text-gray-500 group-hover:text-indigo-500 flex-shrink-0 ml-3 mr-1 mt-1 h-6 w-6"
                                                                        fill="none"
                                                                        viewBox="0 0 24 24"
                                                                        stroke="currentColor"
                                                                    >
                                                                        <path
                                                                            strokeLinecap="round"
                                                                            strokeLinejoin="round"
                                                                            strokeWidth="2"
                                                                            d="M4 6h16M4 10h16M4 14h16M4 18h16"
                                                                        />
                                                                    </svg>
                                                                    <button
                                                                        key={idx}
                                                                        className={classNames(
                                                                            "flex-grow mb-1 text-indigo-700 hover:text-indigo-700 hover:bg-white group rounded-md px-3 py-2 flex items-center text-sm font-medium text-left",
                                                                            menuSectionExistsInErrors(
                                                                                `questions#${idx}`,
                                                                                survey_store.errors
                                                                            )
                                                                                ? "bg-red-100"
                                                                                : survey_store.section ===
                                                                                  `question#${idx}`
                                                                                ? "bg-gray-100"
                                                                                : ""
                                                                        )}
                                                                        onClick={() => {
                                                                            updateSurveyStore({
                                                                                section: "editquestion",
                                                                                questionEditId: q!.id!,
                                                                            });
                                                                        }}
                                                                        aria-current="page"
                                                                    >
                                                                        <span>
                                                                            {survey_store.holdingSurvey!.questions![idx]
                                                                                ?.description &&
                                                                            survey_store.holdingSurvey!.questions![idx]
                                                                                ?.description !== ""
                                                                                ? survey_store.holdingSurvey!
                                                                                      .questions![idx]?.description
                                                                                : "New"}
                                                                        </span>
                                                                    </button>
                                                                </div>
                                                            )}
                                                        </Draggable>
                                                    ))}
                                                    {provided.placeholder}
                                                </div>
                                            )}
                                        </Droppable>
                                    </DragDropContext>
                                </nav>

                                {survey_store.holdingSurvey?.id && (
                                    <div className="w-full text-center">
                                        <Link
                                            to={`/surveys/new`}
                                            className="mt-8 mx-auto w-1/2 bg-green-600 border border-transparent rounded-xl shadow-sm py-1 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                            onClick={() => {
                                                doRefreshIds();
                                            }}
                                        >
                                            (clone me)
                                        </Link>
                                    </div>
                                )}
                            </aside>

                            <div className="space-y-6 sm:px-6 lg:px-0 lg:col-span-9">
                                {survey_store.section === "basics" && (
                                    <div className="shadow sm:rounded-md sm:overflow-hidden">
                                        <div className="bg-white py-6 px-4 space-y-6 sm:p-6">
                                            <div>
                                                <h3 className="text-lg leading-6 font-medium text-gray-900">
                                                    Basic survey information
                                                </h3>
                                                <p className="mt-1 text-sm text-gray-500">
                                                    After this information is entered and the site is created, we will
                                                    have an ID to work from.
                                                </p>
                                            </div>

                                            <div
                                                className={classNames(
                                                    "grid grid-cols-3 gap-6",
                                                    itemExistsInErrors(`basics#name`, survey_store.errors)
                                                        ? "bg-red-100"
                                                        : ""
                                                )}
                                            >
                                                <div className={classNames("col-span-3 sm:col-span-2")}>
                                                    <label
                                                        htmlFor="name"
                                                        className="block text-sm font-medium text-gray-700"
                                                    >
                                                        Survey Name
                                                    </label>
                                                    <div className={classNames("mt-1 rounded-md shadow-sm flex")}>
                                                        <input
                                                            type="text"
                                                            name="name"
                                                            id="name"
                                                            value={survey_store.holdingSurvey?.name ?? ""}
                                                            onChange={(e) =>
                                                                handleSurveyChange(e.target.name!, e.target.value!)
                                                            }
                                                            placeholder="ex: Roundup Quiz, Multi-Tort Quiz"
                                                            className={classNames(
                                                                "focus:ring-indigo-500 focus:border-indigo-500 flex-grow block w-full min-w-0 rounded-none rounded-r-md sm:text-sm border-gray-300"
                                                            )}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div
                                                className={classNames(
                                                    "grid grid-cols-3 gap-6",
                                                    itemExistsInErrors(`basics#appId`, survey_store.errors)
                                                        ? "bg-red-100"
                                                        : ""
                                                )}
                                            >
                                                <div className="col-span-3 sm:col-span-2">
                                                    <label
                                                        htmlFor="name"
                                                        className="block text-sm font-medium text-gray-700"
                                                    >
                                                        App
                                                    </label>
                                                    <div className="mt-1 rounded-md shadow-sm">
                                                        <select
                                                            id="appId"
                                                            name="appId"
                                                            className="focus:ring-indigo-500 focus:border-indigo-500 flex-grow block w-full min-w-0 rounded-none rounded-r-md sm:text-sm border-gray-300"
                                                            value={survey_store.holdingSurvey?.appId}
                                                            onChange={(e) =>
                                                                handleSurveyChange(e.target.name!, e.target.value!)
                                                            }
                                                        >
                                                            <option value=""></option>
                                                            {survey_store.apps.map((a, idx) => (
                                                                <option key={idx} value={a.id}>
                                                                    {a.name}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
                                            <button
                                                type="button"
                                                onClick={() => doValidateAndSave()}
                                                className="bg-indigo-600 border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                            >
                                                Save
                                            </button>
                                        </div>
                                    </div>
                                )}
                                {survey_store.section === "questions" && (
                                    <div className="shadow sm:rounded-md sm:overflow-hidden">
                                        <div className="bg-white py-6 px-4 space-y-6 sm:p-6">
                                            <div>
                                                <h3 className="text-lg leading-6 font-medium text-gray-900">
                                                    Survey Questions
                                                </h3>
                                                <p className="mt-1 text-sm text-gray-500">
                                                    {getApp()?.questionCount && (
                                                        <b>
                                                            This App requires {getApp()?.questionCount} Question(s)
                                                            <br />
                                                            <br />
                                                        </b>
                                                    )}
                                                    Add a new question with the button below.
                                                    <br />
                                                    Select a question to edit on the left menu, or use the drag icon to
                                                    reorder them.
                                                </p>
                                            </div>

                                            <div className="grid grid-cols-3 gap-6">
                                                <button
                                                    type="button"
                                                    className="float-none ml-3 bg-blue-500 hover:bg-blue-700 text-white text-xs font-bold py-1 px-1 rounded-full"
                                                    onClick={() => addQuestion()}
                                                >
                                                    Add Question
                                                </button>
                                            </div>
                                        </div>
                                        <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
                                            <button
                                                type="button"
                                                onClick={() => doValidateAndSave()}
                                                className="bg-indigo-600 border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                            >
                                                Save
                                            </button>
                                        </div>
                                    </div>
                                )}
                                {survey_store.section === "editquestion" && (
                                    <>
                                        <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
                                            <button
                                                type="button"
                                                onClick={() => doValidateAndSave()}
                                                className="bg-indigo-600 border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                            >
                                                Save
                                            </button>
                                        </div>
                                        <div className="shadow sm:rounded-md sm:overflow-hidden">
                                            <div className="bg-white py-6 px-4 space-y-6 sm:p-6">
                                                <div>
                                                    <h3 className="text-lg leading-6 font-medium text-gray-900">
                                                        Question #{String(questionIdx + 1)}
                                                        <button
                                                            type="button"
                                                            className="float-none ml-3 bg-blue-500 hover:bg-blue-700 text-white text-xs font-bold py-1 px-3 rounded-full"
                                                            onClick={() =>
                                                                addOption({
                                                                    qIdx: questionIdx,
                                                                })
                                                            }
                                                        >
                                                            Add Option
                                                        </button>
                                                        <button
                                                            type="button"
                                                            className="float-none ml-3 bg-red-500 hover:bg-red-700 text-white text-xs font-bold py-1 px-3 rounded-full"
                                                            onClick={() =>
                                                                setModalInfo({
                                                                    ...modalInfo,
                                                                    open: true,
                                                                    title: "Remove Question",
                                                                    text: `Are you sure you wish to remove question #${
                                                                        questionIdx + 1
                                                                    }?`,
                                                                    action: () => {
                                                                        doRemoveQuestion(questionIdx);
                                                                    },
                                                                })
                                                            }
                                                        >
                                                            Remove Question
                                                        </button>
                                                    </h3>
                                                </div>
                                                <div
                                                    className={classNames(
                                                        "grid grid-cols-3 gap-6",
                                                        itemExistsInErrors(
                                                            `questions#${questionIdx}#description`,
                                                            survey_store.errors
                                                        )
                                                            ? "bg-red-100"
                                                            : ""
                                                    )}
                                                >
                                                    <div className="col-span-3 sm:col-span-2">
                                                        <label
                                                            htmlFor="description"
                                                            className="block text-sm font-medium text-gray-700"
                                                        >
                                                            Question Text
                                                        </label>
                                                        <div className="mt-1 rounded-md shadow-sm flex">
                                                            <input
                                                                type="text"
                                                                name="description"
                                                                id="description"
                                                                value={
                                                                    survey_store.holdingSurvey?.questions![questionIdx]
                                                                        ?.description ?? ""
                                                                }
                                                                onChange={(e) =>
                                                                    handleQuestionChange(
                                                                        questionIdx,
                                                                        e.target.name!,
                                                                        e.target.value!
                                                                    )
                                                                }
                                                                placeholder="ex: Have you ever used product..."
                                                                className="focus:ring-indigo-500 focus:border-indigo-500 flex-grow block w-full min-w-0 rounded-none rounded-r-md sm:text-sm border-gray-300"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div
                                                    className={classNames(
                                                        "grid grid-cols-3 gap-6",
                                                        itemExistsInErrors(
                                                            `questions#${questionIdx}#type`,
                                                            survey_store.errors
                                                        )
                                                            ? "bg-red-100"
                                                            : ""
                                                    )}
                                                >
                                                    <div className="col-span-3 sm:col-span-2">
                                                        <label
                                                            htmlFor="description"
                                                            className="block text-sm font-medium text-gray-700"
                                                        >
                                                            Question Type
                                                        </label>
                                                        <div className="mt-1 rounded-md shadow-sm flex">
                                                            <select
                                                                id="type"
                                                                name="type"
                                                                className="focus:ring-indigo-500 focus:border-indigo-500 flex-grow block w-full min-w-0 rounded-none rounded-r-md sm:text-sm border-gray-300"
                                                                value={
                                                                    survey_store.holdingSurvey?.questions![questionIdx]
                                                                        ?.type ?? ""
                                                                }
                                                                onChange={(e) =>
                                                                    handleQuestionChange(
                                                                        questionIdx,
                                                                        e.target.name!,
                                                                        e.target.value!
                                                                    )
                                                                }
                                                            >
                                                                <option value=""></option>
                                                                {Object.keys(QuestionType).map((k, idx) => (
                                                                    <option key={k} value={k}>
                                                                        {k}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                                {survey_store.holdingSurvey?.questions![questionIdx]?.type ===
                                                    "PICK_MULTI" && (
                                                    <div
                                                        className={classNames(
                                                            "grid grid-cols-3 gap-6",
                                                            itemExistsInErrors(
                                                                `questions#${questionIdx}#multiSelectPrompt`,
                                                                survey_store.errors
                                                            )
                                                                ? "bg-red-100"
                                                                : ""
                                                        )}
                                                    >
                                                        <div className="col-span-3 sm:col-span-2">
                                                            <label
                                                                htmlFor="description"
                                                                className="block text-sm font-medium text-gray-700"
                                                            >
                                                                Multi Select Prompt (optional)
                                                            </label>
                                                            <div className="mt-1 rounded-md shadow-sm flex">
                                                                <input
                                                                    type="text"
                                                                    name="multiSelectPrompt"
                                                                    id="multiSelectPrompt"
                                                                    value={
                                                                        survey_store.holdingSurvey?.questions![
                                                                            questionIdx
                                                                        ]?.multiSelectPrompt ?? ""
                                                                    }
                                                                    onChange={(e) =>
                                                                        handleQuestionChange(
                                                                            questionIdx,
                                                                            e.target.name!,
                                                                            e.target.value!
                                                                        )
                                                                    }
                                                                    placeholder="ex: Select all that apply"
                                                                    className="focus:ring-indigo-500 focus:border-indigo-500 flex-grow block w-full min-w-0 rounded-none rounded-r-md sm:text-sm border-gray-300"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                                <div
                                                    className={classNames(
                                                        "grid grid-cols-3 gap-6",
                                                        itemExistsInErrors(
                                                            `questions#${questionIdx}#token`,
                                                            survey_store.errors
                                                        )
                                                            ? "bg-red-100"
                                                            : ""
                                                    )}
                                                >
                                                    <div className="col-span-3 sm:col-span-2">
                                                        <label
                                                            htmlFor="description"
                                                            className="block text-sm font-medium text-gray-700"
                                                        >
                                                            Question Token
                                                        </label>
                                                        <div className="mt-1 rounded-md shadow-sm flex">
                                                            <input
                                                                type="text"
                                                                name="token"
                                                                id="token"
                                                                value={
                                                                    survey_store.holdingSurvey?.questions![questionIdx]
                                                                        ?.token ?? ""
                                                                }
                                                                onChange={(e) =>
                                                                    handleQuestionChange(
                                                                        questionIdx,
                                                                        e.target.name!,
                                                                        e.target.value!
                                                                    )
                                                                }
                                                                placeholder="ex: Dx, Engage, Behalf"
                                                                className="focus:ring-indigo-500 focus:border-indigo-500 flex-grow block w-full min-w-0 rounded-none rounded-r-md sm:text-sm border-gray-300"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="grid grid-cols-3 gap-6">
                                                    <div className="col-span-3 sm:col-span-2">
                                                        <label
                                                            htmlFor="description"
                                                            className="block text-sm font-medium text-gray-700"
                                                        >
                                                            Question Bullets (optional - 1 per line)
                                                        </label>
                                                        <div className="mt-1 rounded-md shadow-sm flex">
                                                            <textarea
                                                                rows={5}
                                                                name="bullets"
                                                                id="bullets"
                                                                value={
                                                                    survey_store.holdingSurvey?.questions![questionIdx]
                                                                        ?.bullets ?? ""
                                                                }
                                                                onChange={(e) =>
                                                                    handleQuestionChange(
                                                                        questionIdx,
                                                                        e.target.name!,
                                                                        e.target.value!
                                                                    )
                                                                }
                                                                placeholder="Bullet Options Here - 1 per line!"
                                                                className="focus:ring-indigo-500 focus:border-indigo-500 flex-grow block w-full min-w-0 rounded-none rounded-r-md sm:text-sm border-gray-300"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <DragDropContext onDragEnd={onDragEnd}>
                                            <Droppable droppableId="optionList">
                                                {(provided, snapshot) => (
                                                    <div
                                                        key={`x${questionIdx}`}
                                                        className="mt-3"
                                                        {...provided.droppableProps}
                                                        ref={provided.innerRef}
                                                    >
                                                        {survey_store.holdingSurvey?.questions![
                                                            questionIdx
                                                        ]?.options?.map((o, idx) => (
                                                            <Draggable
                                                                key={`d${idx}`}
                                                                draggableId={`Option#${questionIdx}#${idx}`}
                                                                index={idx}
                                                            >
                                                                {(provided, snapshot) => (
                                                                    <div
                                                                        key={`o${idx}`}
                                                                        className="shadow mt-3 sm:rounded-md sm:overflow-hidden"
                                                                        ref={provided.innerRef}
                                                                        {...provided.draggableProps}
                                                                        {...provided.dragHandleProps}
                                                                    >
                                                                        <div className="bg-white py-6 px-4 space-y-6 sm:p-6">
                                                                            <div>
                                                                                <h3 className="text-lg leading-6 font-medium text-gray-900">
                                                                                    Option #{idx + 1}
                                                                                    <button
                                                                                        type="button"
                                                                                        className="float-none ml-3 bg-red-500 hover:bg-red-700 text-white text-xs font-bold py-1 px-3 rounded-full"
                                                                                        onClick={() =>
                                                                                            setModalInfo({
                                                                                                ...modalInfo,
                                                                                                open: true,
                                                                                                title: "Remove Option",
                                                                                                text: `Are you sure you wish to remove option #${
                                                                                                    idx + 1
                                                                                                }?`,
                                                                                                action: () => {
                                                                                                    removeOption({
                                                                                                        qIdx: questionIdx,
                                                                                                        idx: idx,
                                                                                                    });
                                                                                                },
                                                                                            })
                                                                                        }
                                                                                    >
                                                                                        Remove Option
                                                                                    </button>
                                                                                </h3>
                                                                            </div>
                                                                            <div
                                                                                className={classNames(
                                                                                    "grid grid-cols-3 gap-6",
                                                                                    itemExistsInErrors(
                                                                                        `questions#${questionIdx}#options#${idx}#value`,
                                                                                        survey_store.errors
                                                                                    )
                                                                                        ? "bg-red-100"
                                                                                        : ""
                                                                                )}
                                                                            >
                                                                                <div className="col-span-3 sm:col-span-2">
                                                                                    <label
                                                                                        htmlFor="description"
                                                                                        className="block text-sm font-medium text-gray-700"
                                                                                    >
                                                                                        Text
                                                                                    </label>
                                                                                    <div className="mt-1 rounded-md shadow-sm flex">
                                                                                        <input
                                                                                            type="text"
                                                                                            name={`value#${o?.id}`}
                                                                                            id={`value#${o?.id}`}
                                                                                            value={o?.value ?? ""}
                                                                                            onChange={(e) =>
                                                                                                handleQuestionOptionChange(
                                                                                                    questionIdx,
                                                                                                    idx,
                                                                                                    e.target.name!,
                                                                                                    e.target.value!
                                                                                                )
                                                                                            }
                                                                                            placeholder="ex: Yes/No/Myself/Unsure"
                                                                                            className="focus:ring-indigo-500 focus:border-indigo-500 flex-grow block w-full min-w-0 rounded-none rounded-r-md sm:text-sm border-gray-300"
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            {survey_store.holdingSurvey?.questions![
                                                                                questionIdx
                                                                            ]?.type === "PICK_MULTI" && (
                                                                                <>
                                                                                    <div
                                                                                        className={classNames(
                                                                                            "grid grid-cols-3 gap-6",
                                                                                            itemExistsInErrors(
                                                                                                `questions#${questionIdx}#options#${idx}#subValue`,
                                                                                                survey_store.errors
                                                                                            )
                                                                                                ? "bg-red-100"
                                                                                                : ""
                                                                                        )}
                                                                                    >
                                                                                        <div className="col-span-3 sm:col-span-2">
                                                                                            <label
                                                                                                htmlFor="description"
                                                                                                className="block text-sm font-medium text-gray-700"
                                                                                            >
                                                                                                Sub Value (optional)
                                                                                            </label>
                                                                                            <div className="mt-1 rounded-md shadow-sm flex">
                                                                                                <input
                                                                                                    type="text"
                                                                                                    name={`subValue#${o?.id}`}
                                                                                                    id={`subValue#${o?.id}`}
                                                                                                    value={
                                                                                                        o?.subValue ??
                                                                                                        ""
                                                                                                    }
                                                                                                    onChange={(e) =>
                                                                                                        handleQuestionOptionChange(
                                                                                                            questionIdx,
                                                                                                            idx,
                                                                                                            e.target
                                                                                                                .name!,
                                                                                                            e.target
                                                                                                                .value!
                                                                                                        )
                                                                                                    }
                                                                                                    placeholder="ex: Such as taxes, fees, etc."
                                                                                                    className="focus:ring-indigo-500 focus:border-indigo-500 flex-grow block w-full min-w-0 rounded-none rounded-r-md sm:text-sm border-gray-300"
                                                                                                />
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div
                                                                                        className={classNames(
                                                                                            "grid grid-cols-3 gap-6",
                                                                                            itemExistsInErrors(
                                                                                                `questions#${questionIdx}#options#${idx}#section`,
                                                                                                survey_store.errors
                                                                                            )
                                                                                                ? "bg-red-100"
                                                                                                : ""
                                                                                        )}
                                                                                    >
                                                                                        <div className="col-span-3 sm:col-span-2">
                                                                                            <label
                                                                                                htmlFor="description"
                                                                                                className="block text-sm font-medium text-gray-700"
                                                                                            >
                                                                                                Attach Section Heading
                                                                                            </label>
                                                                                            <div className="mt-1 rounded-md shadow-sm flex">
                                                                                                <input
                                                                                                    type="text"
                                                                                                    name={`section#${o?.id}`}
                                                                                                    id={`section#${o?.id}`}
                                                                                                    value={
                                                                                                        o?.section ?? ""
                                                                                                    }
                                                                                                    onChange={(e) =>
                                                                                                        handleQuestionOptionChange(
                                                                                                            questionIdx,
                                                                                                            idx,
                                                                                                            e.target
                                                                                                                .name!,
                                                                                                            e.target
                                                                                                                .value!
                                                                                                        )
                                                                                                    }
                                                                                                    placeholder="ex: Expenses"
                                                                                                    className="focus:ring-indigo-500 focus:border-indigo-500 flex-grow block w-full min-w-0 rounded-none rounded-r-md sm:text-sm border-gray-300"
                                                                                                />
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </>
                                                                            )}
                                                                            <div
                                                                                className={classNames(
                                                                                    "grid grid-cols-3 gap-6",
                                                                                    itemExistsInErrors(
                                                                                        `questions#${questionIdx}#options#${idx}#action`,
                                                                                        survey_store.errors
                                                                                    )
                                                                                        ? "bg-red-100"
                                                                                        : ""
                                                                                )}
                                                                            >
                                                                                <div className="col-span-3 sm:col-span-2">
                                                                                    <label
                                                                                        htmlFor="description"
                                                                                        className="block text-sm font-medium text-gray-700"
                                                                                    >
                                                                                        Action
                                                                                    </label>
                                                                                    <div className="mt-1 rounded-md shadow-sm flex">
                                                                                        <select
                                                                                            id={`action#${o?.id}`}
                                                                                            name={`action#${o?.id}`}
                                                                                            className="focus:ring-indigo-500 focus:border-indigo-500 flex-grow block w-full min-w-0 rounded-none rounded-r-md sm:text-sm border-gray-300"
                                                                                            value={o?.action ?? ""}
                                                                                            onChange={(e) =>
                                                                                                handleQuestionOptionChange(
                                                                                                    questionIdx,
                                                                                                    idx,
                                                                                                    e.target.name!,
                                                                                                    e.target.value!
                                                                                                )
                                                                                            }
                                                                                        >
                                                                                            <option value="">
                                                                                                (no action - proceed to
                                                                                                next step)
                                                                                            </option>
                                                                                            {Object.keys(OptionAction)
                                                                                                .filter((o) => {
                                                                                                    if (
                                                                                                        survey_store
                                                                                                            .holdingSurvey
                                                                                                            ?.questions![
                                                                                                            questionIdx
                                                                                                        ]?.type ===
                                                                                                            "PICK_MULTI" &&
                                                                                                        [
                                                                                                            "EDUCATE",
                                                                                                        ].includes(o)
                                                                                                    ) {
                                                                                                        return false;
                                                                                                    } else {
                                                                                                        return true;
                                                                                                    }
                                                                                                })
                                                                                                .map((k, idx) => (
                                                                                                    <option
                                                                                                        key={idx}
                                                                                                        value={k}
                                                                                                    >
                                                                                                        {k}
                                                                                                    </option>
                                                                                                ))}
                                                                                        </select>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            {o?.action &&
                                                                                ["EDUCATE", "DISQUALIFY"].includes(
                                                                                    o?.action
                                                                                ) && (
                                                                                    <div className="grid grid-cols-3 gap-6">
                                                                                        <div className="col-span-3 sm:col-span-2">
                                                                                            <label
                                                                                                htmlFor={`actionText#${o?.id}`}
                                                                                                className="block text-sm font-medium text-gray-700"
                                                                                            >
                                                                                                {o?.action} Text
                                                                                            </label>
                                                                                            <div className="mt-1 rounded-md shadow-sm flex">
                                                                                                <textarea
                                                                                                    rows={10}
                                                                                                    name={`actionText#${o?.id}`}
                                                                                                    id={`actionText#${o?.id}`}
                                                                                                    value={
                                                                                                        o?.actionText ??
                                                                                                        ""
                                                                                                    }
                                                                                                    onChange={(e) =>
                                                                                                        handleQuestionOptionChange(
                                                                                                            questionIdx,
                                                                                                            idx,
                                                                                                            e.target
                                                                                                                .name!,
                                                                                                            e.target
                                                                                                                .value!
                                                                                                        )
                                                                                                    }
                                                                                                    placeholder="ex: Sorry, but this is required.."
                                                                                                    className="focus:ring-indigo-500 focus:border-indigo-500 flex-grow block w-full min-w-0 rounded-none rounded-r-md sm:text-sm border-gray-300"
                                                                                                />
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                )}
                                                                            {o?.action === "QUALIFY" && (
                                                                                <div className="grid grid-cols-3 gap-6">
                                                                                    <div className="col-span-3 sm:col-span-2">
                                                                                        <label
                                                                                            htmlFor={`qualifySource#${o?.id}`}
                                                                                            className="block text-sm font-medium text-gray-700"
                                                                                        >
                                                                                            Qualifies For{" "}
                                                                                            <span
                                                                                                className="float-none ml-3 bg-blue-500 hover:bg-blue-700 text-white text-s font-bold py-1 px-3 mb-1 "
                                                                                                onClick={() =>
                                                                                                    addQuestionOptionMultiStringChange(
                                                                                                        questionIdx,
                                                                                                        idx,
                                                                                                        "qualifySource"
                                                                                                    )
                                                                                                }
                                                                                            >
                                                                                                +
                                                                                            </span>
                                                                                        </label>
                                                                                        {o.qualifySource?.map(
                                                                                            (i, iIdx) => {
                                                                                                return (
                                                                                                    <div
                                                                                                        className="mt-1 rounded-md shadow-sm flex"
                                                                                                        key={iIdx}
                                                                                                    >
                                                                                                        <select
                                                                                                            name={`qualifySource#${o?.id}#${iIdx}`}
                                                                                                            id={`qualifySource#${o?.id}#${iIdx}`}
                                                                                                            className="focus:ring-indigo-500 focus:border-indigo-500 flex-grow block w-full min-w-0 rounded-none rounded-r-md sm:text-sm border-gray-300"
                                                                                                            value={i!}
                                                                                                            onChange={(
                                                                                                                e
                                                                                                            ) =>
                                                                                                                handleQuestionOptionMultiStringChange(
                                                                                                                    questionIdx,
                                                                                                                    idx,
                                                                                                                    iIdx,
                                                                                                                    e
                                                                                                                        .target
                                                                                                                        .name!,
                                                                                                                    e
                                                                                                                        .target
                                                                                                                        .value!,
                                                                                                                    "qualifySource"
                                                                                                                )
                                                                                                            }
                                                                                                        >
                                                                                                            <option value=""></option>
                                                                                                            {survey_store.sources
                                                                                                                .sort(
                                                                                                                    (
                                                                                                                        a,
                                                                                                                        b
                                                                                                                    ) => {
                                                                                                                        const A =
                                                                                                                            a.name!.toUpperCase();
                                                                                                                        const B =
                                                                                                                            b.name!.toUpperCase();
                                                                                                                        let comparison = 0;
                                                                                                                        if (
                                                                                                                            A >
                                                                                                                            B
                                                                                                                        ) {
                                                                                                                            comparison = 1;
                                                                                                                        } else if (
                                                                                                                            A <
                                                                                                                            B
                                                                                                                        ) {
                                                                                                                            comparison =
                                                                                                                                -1;
                                                                                                                        }
                                                                                                                        return comparison;
                                                                                                                    }
                                                                                                                )
                                                                                                                .map(
                                                                                                                    (
                                                                                                                        a,
                                                                                                                        idx
                                                                                                                    ) => (
                                                                                                                        <option
                                                                                                                            key={
                                                                                                                                idx
                                                                                                                            }
                                                                                                                            value={
                                                                                                                                a.id
                                                                                                                            }
                                                                                                                        >
                                                                                                                            {
                                                                                                                                a.name
                                                                                                                            }
                                                                                                                        </option>
                                                                                                                    )
                                                                                                                )}
                                                                                                        </select>{" "}
                                                                                                        <span
                                                                                                            className="float-none ml-3 bg-red-500 hover:bg-blue-700 text-white text-s font-bold py-1 px-3 mb-1 "
                                                                                                            onClick={() =>
                                                                                                                removeQuestionOptionMultiStringChange(
                                                                                                                    questionIdx,
                                                                                                                    idx,
                                                                                                                    iIdx,
                                                                                                                    "qualifySource"
                                                                                                                )
                                                                                                            }
                                                                                                        >
                                                                                                            -
                                                                                                        </span>
                                                                                                    </div>
                                                                                                );
                                                                                            }
                                                                                        )}
                                                                                    </div>
                                                                                </div>
                                                                            )}
                                                                            {o?.action === "DISQUALIFYFOR" && (
                                                                                <div className="grid grid-cols-3 gap-6">
                                                                                    <div className="col-span-3 sm:col-span-2">
                                                                                        <label
                                                                                            htmlFor={`disqualifySource#${o?.id}`}
                                                                                            className="block text-sm font-medium text-gray-700"
                                                                                        >
                                                                                            Dis-Qualifies For{" "}
                                                                                            <span
                                                                                                className="float-none ml-3 bg-blue-500 hover:bg-blue-700 text-white text-s font-bold py-1 px-3 mb-1 "
                                                                                                onClick={() =>
                                                                                                    addQuestionOptionMultiStringChange(
                                                                                                        questionIdx,
                                                                                                        idx,
                                                                                                        "disqualifySource"
                                                                                                    )
                                                                                                }
                                                                                            >
                                                                                                +
                                                                                            </span>
                                                                                        </label>
                                                                                        {o.disqualifySource?.map(
                                                                                            (i, iIdx) => {
                                                                                                return (
                                                                                                    <div
                                                                                                        className="mt-1 rounded-md shadow-sm flex"
                                                                                                        key={iIdx}
                                                                                                    >
                                                                                                        <select
                                                                                                            name={`disqualifySource#${o?.id}#${iIdx}`}
                                                                                                            id={`disqualifySource#${o?.id}#${iIdx}`}
                                                                                                            className="focus:ring-indigo-500 focus:border-indigo-500 flex-grow block w-full min-w-0 rounded-none rounded-r-md sm:text-sm border-gray-300"
                                                                                                            value={i!}
                                                                                                            onChange={(
                                                                                                                e
                                                                                                            ) =>
                                                                                                                handleQuestionOptionMultiStringChange(
                                                                                                                    questionIdx,
                                                                                                                    idx,
                                                                                                                    iIdx,
                                                                                                                    e
                                                                                                                        .target
                                                                                                                        .name!,
                                                                                                                    e
                                                                                                                        .target
                                                                                                                        .value!,
                                                                                                                    "disqualifySource"
                                                                                                                )
                                                                                                            }
                                                                                                        >
                                                                                                            <option value=""></option>
                                                                                                            {survey_store.sources
                                                                                                                .sort(
                                                                                                                    (
                                                                                                                        a,
                                                                                                                        b
                                                                                                                    ) => {
                                                                                                                        const A =
                                                                                                                            a.name!.toUpperCase();
                                                                                                                        const B =
                                                                                                                            b.name!.toUpperCase();
                                                                                                                        let comparison = 0;
                                                                                                                        if (
                                                                                                                            A >
                                                                                                                            B
                                                                                                                        ) {
                                                                                                                            comparison = 1;
                                                                                                                        } else if (
                                                                                                                            A <
                                                                                                                            B
                                                                                                                        ) {
                                                                                                                            comparison =
                                                                                                                                -1;
                                                                                                                        }
                                                                                                                        return comparison;
                                                                                                                    }
                                                                                                                )
                                                                                                                .map(
                                                                                                                    (
                                                                                                                        a,
                                                                                                                        idx
                                                                                                                    ) => (
                                                                                                                        <option
                                                                                                                            key={
                                                                                                                                idx
                                                                                                                            }
                                                                                                                            value={
                                                                                                                                a.id
                                                                                                                            }
                                                                                                                        >
                                                                                                                            {
                                                                                                                                a.name
                                                                                                                            }
                                                                                                                        </option>
                                                                                                                    )
                                                                                                                )}
                                                                                                        </select>{" "}
                                                                                                        <span
                                                                                                            className="float-none ml-3 bg-red-500 hover:bg-blue-700 text-white text-s font-bold py-1 px-3 mb-1 "
                                                                                                            onClick={() =>
                                                                                                                removeQuestionOptionMultiStringChange(
                                                                                                                    questionIdx,
                                                                                                                    idx,
                                                                                                                    iIdx,
                                                                                                                    "disqualifySource"
                                                                                                                )
                                                                                                            }
                                                                                                        >
                                                                                                            -
                                                                                                        </span>
                                                                                                    </div>
                                                                                                );
                                                                                            }
                                                                                        )}
                                                                                    </div>
                                                                                </div>
                                                                            )}
                                                                            {o?.action === "TORTQUALIFY" && (
                                                                                <div className="grid grid-cols-3 gap-6">
                                                                                    <div className="col-span-3 sm:col-span-2">
                                                                                        <label
                                                                                            htmlFor={`tortQualifySurveyIds#${o?.id}`}
                                                                                            className="block text-sm font-medium text-gray-700"
                                                                                        >
                                                                                            Included Torts{" "}
                                                                                            <span
                                                                                                className="float-none ml-3 bg-blue-500 hover:bg-blue-700 text-white text-s font-bold py-1 px-3 mb-1 "
                                                                                                onClick={() =>
                                                                                                    addQuestionOptionMultiStringChange(
                                                                                                        questionIdx,
                                                                                                        idx,
                                                                                                        "tortQualifySurveyIds"
                                                                                                    )
                                                                                                }
                                                                                            >
                                                                                                +
                                                                                            </span>
                                                                                        </label>
                                                                                        {o.tortQualifySurveyIds?.map(
                                                                                            (i, iIdx) => {
                                                                                                return (
                                                                                                    <div
                                                                                                        className="mt-1 rounded-md shadow-sm flex"
                                                                                                        key={iIdx}
                                                                                                    >
                                                                                                        <select
                                                                                                            name={`tortQualifySurveyIds#${o?.id}#${iIdx}`}
                                                                                                            id={`tortQualifySurveyIds#${o?.id}#${iIdx}`}
                                                                                                            className="focus:ring-indigo-500 focus:border-indigo-500 flex-grow block w-full min-w-0 rounded-none rounded-r-md sm:text-sm border-gray-300"
                                                                                                            value={i!}
                                                                                                            onChange={(
                                                                                                                e
                                                                                                            ) =>
                                                                                                                handleQuestionOptionMultiStringChange(
                                                                                                                    questionIdx,
                                                                                                                    idx,
                                                                                                                    iIdx,
                                                                                                                    e
                                                                                                                        .target
                                                                                                                        .name!,
                                                                                                                    e
                                                                                                                        .target
                                                                                                                        .value!,
                                                                                                                    "tortQualifySurveyIds"
                                                                                                                )
                                                                                                            }
                                                                                                        >
                                                                                                            <option value=""></option>
                                                                                                            {survey_store.tqsurveys.map(
                                                                                                                (
                                                                                                                    a,
                                                                                                                    idx
                                                                                                                ) => (
                                                                                                                    <option
                                                                                                                        key={
                                                                                                                            idx
                                                                                                                        }
                                                                                                                        value={
                                                                                                                            a.id
                                                                                                                        }
                                                                                                                    >
                                                                                                                        [
                                                                                                                        {
                                                                                                                            a.source
                                                                                                                        }

                                                                                                                        ]{" "}
                                                                                                                        {
                                                                                                                            a.name
                                                                                                                        }
                                                                                                                    </option>
                                                                                                                )
                                                                                                            )}
                                                                                                        </select>{" "}
                                                                                                        <span
                                                                                                            className="float-none ml-3 bg-red-500 hover:bg-blue-700 text-white text-s font-bold py-1 px-3 mb-1 "
                                                                                                            onClick={() =>
                                                                                                                removeQuestionOptionMultiStringChange(
                                                                                                                    questionIdx,
                                                                                                                    idx,
                                                                                                                    iIdx,
                                                                                                                    "tortQualifySurveyIds"
                                                                                                                )
                                                                                                            }
                                                                                                        >
                                                                                                            -
                                                                                                        </span>
                                                                                                    </div>
                                                                                                );
                                                                                            }
                                                                                        )}
                                                                                    </div>
                                                                                </div>
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                )}
                                                            </Draggable>
                                                        ))}
                                                        {provided.placeholder}
                                                    </div>
                                                )}
                                            </Droppable>
                                        </DragDropContext>
                                        <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
                                            <button
                                                type="button"
                                                onClick={() => doValidateAndSave()}
                                                className="bg-indigo-600 border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                            >
                                                Save
                                            </button>
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                )}
            </main>
            {modalInfo.open && <Confirm info={modalInfo} />}
        </>
    );
}

export default SurveysModify;
