import { getSiteViewersByStatus } from "../customGql/queries";
import { SiteViewerResponse } from "billeci-private-sitebuilder-schema/dist/API";
import { API } from "aws-amplify";

const reloadSiteViewer = /* GraphQL */ `
    mutation ReloadSiteViewer($id: String!, $refreshCode: Boolean) {
        reloadSiteViewer(id: $id, refreshCode: $refreshCode) {
            ip
            siteId
            sessionId
            status
        }
    }
`;

export const doReloadNotify = async (siteId) => {
    console.log(`DRN: ${siteId}`);
    await API.graphql({ query: reloadSiteViewer, variables: { id: siteId } });
};

export const doRefreshNotify = async (siteId) => {
    await API.graphql({ query: reloadSiteViewer, variables: { id: siteId, refreshCode: true } });
};

export const notifyRealtime = async (resource: string, id: string) => {
    const response: any = await API.graphql({ query: getSiteViewersByStatus, variables: { status: "ACTIVE" } });
    var responseData = response.data.getSiteViewersByStatus as SiteViewerResponse[];

    // console.log(id);
    // console.log(resource);

    var notifiedSiteIds: string[] = [];

    for (let svr of responseData) {
        // console.log(svr);
        if (resource === "site") {
            if (svr.siteId === id) {
                doReloadNotify(svr.siteId);
            }
        } else if (resource === "firm") {
            if (svr.site?.firm?.id === id) {
                // console.log("sending realtime!!");
                doReloadNotify(svr.siteId);
            }
        } else if (resource === "app") {
            if (svr.site?.app?.id === id) {
                doReloadNotify(svr.siteId);
            }
        } else if (resource === "survey") {
            if (svr.site?.survey?.id === id) {
                doReloadNotify(svr.siteId);
            }
        } else if (resource === "tqsurvey") {
            for (let q of svr.site?.survey?.questions ?? []) {
                for (let o of q!.options ?? []) {
                    if (o?.tortQualifySurveyIds && o.tortQualifySurveyIds!.includes(id)) {
                        if (!notifiedSiteIds.includes(svr.siteId!)) {
                            doReloadNotify(svr.siteId);
                            notifiedSiteIds.push(svr.siteId!);
                        }
                    }
                }
            }
        } else if (resource === "content") {
            if (svr.site?.content?.id === id) {
                doReloadNotify(svr.siteId);
            }
        }
    }
};
