import { useState, useCallback, useEffect } from "react";
import { useParams } from "react-router-dom";

import { API } from "aws-amplify";

import {
    App,
    Site,
    Content,
    Domain,
    Firm,
    Source,
    Survey,
    InputSiteModify,
    PlatformIdentifier,
    InputPlatformIdentifierModify,
    SiteViewerResponse,
} from "billeci-private-sitebuilder-schema/dist/API";

import Confirm, { ModalInfo } from "../components/Confirm";

import { getSitesDomainCheck } from "../customGql/queries";

import Loader from "../components/Loader";

import {
    getSite,
    getApps,
    getFirms,
    getSurveys,
    getContents,
    getDomains,
    getPlatformIdentifiers,
    getSiteViewersByStatus,
    getSources,
} from "billeci-private-sitebuilder-schema/dist/graphql/queries";

import * as _ from "lodash";

import SiteStore, {
    updateSiteStore,
    modifySiteDetails,
    modifyHoldingPlatformIdentifierDetails,
} from "../stores/SiteStore";
import { useStore } from "effector-react";

import Header from "../components/Header";

import { notifyRealtime } from "../methods/notifyRealtime";

import { CheckCircleIcon } from "@heroicons/react/solid";

import { scrollToTop, menuSectionExistsInErrors, itemExistsInErrors } from "../utils/utils";
import { ToastMessage, ModificationError } from "../types/types";

import { classNames } from "../utils/utils";

function SitesModify() {
    type routeParams = {
        site_id?: string;
    };

    const route_params: routeParams = useParams();

    const site_store = useStore(SiteStore);
    const [toastMsg, toastMsgSet] = useState<ToastMessage>();
    const [loading, loadingSet] = useState(true);
    const [specApp, specAppSet] = useState<App>();
    const [realtimeActive, realtimeActiveSet] = useState([] as string[]);
    const [modalInfo, setModalInfo] = useState<ModalInfo>({
        title: "Title",
        text: "Text",
        positive: "OK",
        negative: "Cancel",
        close: () => {
            setModalInfo({
                ...modalInfo,
                open: false,
            });
        },
        action: () => {},
        open: false,
    });

    const parsePlatformIdentifiers = (platformIdentifiers: PlatformIdentifier[], values?: PlatformIdentifier[]) => {
        var result: any = {};
        for (let pi of platformIdentifiers) {
            if (!result[pi.platform!]) {
                result[pi.platform!] = {};
            }
            result[pi.platform!][pi.name!] = pi;
            if (values?.[pi.platform!]?.[pi.name!]?.value) {
                result[pi.platform!][pi.name!].value = values[pi.platform!][pi.name!].value;
            }
        }
        return result;
    };

    const doSort = (toSort: any[], field?: string) => {
        return toSort.sort((a, b) => {
            const A = a[field ?? "name"]!.toUpperCase();
            const B = b[field ?? "name"]!.toUpperCase();
            let comparison = 0;
            if (A > B) {
                comparison = 1;
            } else if (A < B) {
                comparison = -1;
            }
            return comparison;
        });
    };

    useEffect(() => {
        const loadData = async () => {
            var promises: any = {};

            var mode: string = route_params.site_id ? "EDIT" : "ADD";
            var graphQlValues: any;

            updateSiteStore({
                mode: mode,
                section: "basics",
            });

            promises["firms"] = API.graphql({ query: getFirms });
            promises["sources"] = API.graphql({ query: getSources });
            promises["apps"] = API.graphql({ query: getApps });
            promises["domains"] = API.graphql({ query: getDomains });
            promises["surveys"] = API.graphql({ query: getSurveys });
            promises["contents"] = API.graphql({ query: getContents });
            promises["identifiers"] = API.graphql({ query: getPlatformIdentifiers });

            if (mode === "EDIT") {
                promises["site"] = API.graphql({ query: getSite, variables: { id: route_params.site_id } });
                promises["active"] = API.graphql({ query: getSiteViewersByStatus, variables: { status: "ACTIVE" } });
                try {
                    /// query
                    graphQlValues = await Promise.all([
                        promises["apps"], // 0
                        promises["contents"], // 1
                        promises["domains"], // 2
                        promises["firms"], // 3
                        promises["surveys"], // 4
                        promises["identifiers"], // 5
                        promises["site"], // 6
                        promises["active"], // 7
                        promises["sources"], // 8
                    ]);

                    // get pulled Site
                    var rawSite = graphQlValues[6].data.getSite as Site;

                    // convert to InputSiteModify
                    var inputSite = _.cloneDeep(rawSite) as unknown as InputSiteModify;

                    var queryHolder = {};
                    queryHolder["apps"] = graphQlValues[0].data.getApps as App[];
                    queryHolder["contents"] = graphQlValues[1].data.getContents as Content[];
                    queryHolder["domains"] = graphQlValues[2].data.getDomains as Domain[];
                    queryHolder["firms"] = graphQlValues[3].data.getFirms as Firm[];
                    queryHolder["surveys"] = graphQlValues[4].data.getSurveys as Survey[];
                    queryHolder["identifiers"] = graphQlValues[5].data.getPlatformIdentifiers as PlatformIdentifier[];
                    queryHolder["sources"] = graphQlValues[8].data.getSources as Source[];

                    var realTimeActive: string[] = [];
                    for (let svr of graphQlValues[7].data.getSiteViewersByStatus as SiteViewerResponse[]) {
                        realTimeActive.push(svr!.siteId!);
                    }

                    realtimeActiveSet(realTimeActive);

                    /// parse IDs out of sub items and into Input format as `${resource}Id`
                    for (let k of Object.keys(queryHolder)) {
                        var key = `${k.slice(0, -1)}`;
                        var specId = rawSite[`${key}`]?.id;
                        if (specId) {
                            inputSite[`${key}Id`] = specId;
                        }
                    }

                    delete inputSite["deploymentTransactions"];
                    delete inputSite["lastCommit"];
                    delete inputSite["app"];
                    delete inputSite["domain"];
                    delete inputSite["firm"];
                    delete inputSite["content"];
                    delete inputSite["survey"];
                    delete inputSite["tortQualifySurvey"];

                    updateSiteStore({
                        holdingSite: inputSite,
                        apps: queryHolder["apps"],
                        contents: queryHolder["contents"],
                        domains: queryHolder["domains"],
                        firms: queryHolder["firms"],
                        sources: queryHolder["sources"],
                        surveys: queryHolder["surveys"],
                        platformIdentifiers: parsePlatformIdentifiers(queryHolder["identifiers"]),
                        holdingPlatformIdentifiers: parsePlatformIdentifiers(
                            queryHolder["identifiers"],
                            parsePlatformIdentifiers(rawSite!.platformIdentifiers as unknown as PlatformIdentifier[])
                        ),
                    });

                    if (inputSite.appId) {
                        specAppSet((queryHolder["apps"].filter((a) => a.id === inputSite.appId) ?? []).pop());
                    }

                    loadingSet(false);
                } catch (error) {
                    console.log(error);
                }
            } else {
                try {
                    /// query
                    graphQlValues = await Promise.all([
                        promises["apps"], // 0
                        promises["contents"], // 1
                        promises["domains"], // 2
                        promises["firms"], // 3
                        promises["surveys"], // 4
                        promises["identifiers"], // 5
                        promises["sources"], // 6
                    ]);

                    updateSiteStore({
                        apps: graphQlValues[0].data.getApps as App[],
                        contents: graphQlValues[1].data.getContents as Content[],
                        domains: graphQlValues[2].data.getDomains as Domain[],
                        firms: graphQlValues[3].data.getFirms as Firm[],
                        sources: graphQlValues[6].data.getSources as Source[],
                        surveys: graphQlValues[4].data.getSurveys as Survey[],
                        platformIdentifiers: parsePlatformIdentifiers(
                            graphQlValues[5].data.getPlatformIdentifiers as PlatformIdentifier[]
                        ),
                        holdingPlatformIdentifiers: parsePlatformIdentifiers(
                            graphQlValues[5].data.getPlatformIdentifiers as PlatformIdentifier[]
                        ),
                    });
                    loadingSet(false);
                } catch (error) {
                    console.log(error);
                }
            }
        };
        loadData();
    }, [route_params]);

    const handleHoldingPlatformIdentifierChange = (fieldName, fieldValue) => {
        modifyHoldingPlatformIdentifierDetails({ fieldName: fieldName, fieldValue: fieldValue });
    };

    const handleSiteChange = (fieldName, fieldValue) => {
        modifySiteDetails({ fieldName: fieldName, fieldValue: fieldValue });
    };

    const viewRealtime = (id?: string): void => {
        window.open(`/sites/realtime/${id}`, id);
    };

    const doValidateAndSave = useCallback(async () => {
        scrollToTop();

        if (site_store.holdingSite.uri) {
            site_store.holdingSite.uri = site_store.holdingSite.uri.replace(/[^a-z0-9/-]+/g, "");
            site_store.holdingSite.uri = site_store.holdingSite.uri.replace(/^\/*/g, "");
            site_store.holdingSite.uri = site_store.holdingSite.uri.replace(/\/*$/g, "");
            if (site_store.holdingSite.uri === "") {
                site_store.holdingSite.uri = "/";
            } else {
                site_store.holdingSite.uri = `/${site_store.holdingSite.uri}`;
            }
        }

        const addSite = /* GraphQL */ `
            mutation AddSite($input: InputSiteModify!) {
                addSite(input: $input) {
                    id
                }
            }
        `;
        const updateSite = /* GraphQL */ `
            mutation UpdateSite($input: InputSiteModify!) {
                updateSite(input: $input) {
                    id
                }
            }
        `;

        /// check for various things
        //////
        var errors: ModificationError[] = [];

        let selectedApp = (site_store.apps.filter((e) => e.id === site_store.holdingSite.appId) ?? []).pop();

        /// basic fields to check
        var toCheck = ["uri", "appId", "firmId", "surveyId", "domainId", "contentId"];
        for (let c of toCheck) {
            /// only require survey ID if new system not enabled, and we're not in lock-to mode
            if (c === "surveyId" && (site_store.holdingSite.surveySystemEnable || selectedApp?.lockTort)) {
                continue;
            }

            if (!site_store.holdingSite[c]) {
                errors.push({
                    menuSection: ["basics"],
                    item: `basics#${c}`,
                    itemDescription: "Basic Section",
                    message: `Survey ${c} missing`,
                });
            }
        }

        /// if v2 is enabled, make sure a survey is selected
        if (site_store.holdingSite.surveySystemEnable) {
            if (!site_store.holdingSite.surveySystemSourceId) {
                errors.push({
                    menuSection: ["basics"],
                    item: `basics#surveySystemSourceId`,
                    itemDescription: "Basic Section",
                    message: `Survey (V2) SourceId missing`,
                });
            }
        }

        /// if v2 is enabled, make sure a survey is selected
        if (selectedApp?.lockTort && !site_store.holdingSite.lockedSourceId) {
            errors.push({
                menuSection: ["basics"],
                item: `basics#lockedSourceId`,
                itemDescription: "Basic Section",
                message: `Locked Source ID missing as required by selected app`,
            });
        }

        /// check to see if any site already has this domain/path combo
        var existingSiteResult: any = await API.graphql({ query: getSitesDomainCheck });
        var existingSites = existingSiteResult.data.getSites as Partial<Site>[];
        for (let s of existingSites) {
            if (s.uri === site_store.holdingSite.uri && s.domain?.id === site_store.holdingSite.domainId) {
                if (!site_store.holdingSite.id || (site_store.holdingSite.id && site_store.holdingSite.id !== s.id)) {
                    console.log("match!");
                    errors.push({
                        menuSection: ["basics"],
                        item: [`basics#domainId`, `basics#uri`],
                        itemDescription: "Basic Section",
                        message: `A Site already exists with same DOMAIN and PATH`,
                    });
                }
            }
        }

        if (Object.keys(errors).length > 0) {
            toastMsgSet({ message: "Errors! Please Fix!", type: "ERROR" });
        }

        /// set errors in the state for visual representation
        updateSiteStore({
            errors: errors,
        });

        // if we don't have any errors, time to submit it
        if (Object.keys(errors).length === 0) {
            /// send it
            console.log("send it");
            var promises: any = {};

            /// dereference the holdingPlatformIdentifiers
            var platformIdentifiers: InputPlatformIdentifierModify[] = [];
            for (let platform of Object.keys(site_store.holdingPlatformIdentifiers)) {
                for (let pname of Object.keys(site_store.holdingPlatformIdentifiers[platform])) {
                    platformIdentifiers.push({
                        platform: platform,
                        name: pname,
                        value: site_store.holdingPlatformIdentifiers[platform][pname].value,
                    });
                }
            }
            site_store.holdingSite.platformIdentifiers = platformIdentifiers;

            let postSite = _.cloneDeep(site_store.holdingSite);
            delete postSite["__typename"];

            if (postSite.deployActive !== false) {
                postSite.deployActive = true;
            }

            promises["site"] = API.graphql({
                query: site_store.mode === "ADD" ? addSite : updateSite,
                variables: {
                    input: postSite,
                },
            });
            try {
                var add_result = await Promise.all([promises["site"]]);
                toastMsgSet({ message: "Saved Successfully!" });
                notifyRealtime("site", site_store.holdingSite!.id!);
                if (site_store.mode === "ADD") {
                    setTimeout(function () {
                        window.location.href = `/sites/${(add_result as any)[0].data.addSite.id}`;
                    }, 2000);
                }
            } catch (error) {
                console.log(error);
            }
        }
    }, [site_store.holdingSite, site_store.mode, site_store.holdingPlatformIdentifiers, site_store.apps]);

    return (
        <>
            <Header title={`${site_store.mode} Site`} toast={toastMsg} errors={site_store.errors} />
            <main>
                {loading ? (
                    <Loader />
                ) : (
                    <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
                        <div className="lg:grid lg:grid-cols-12 lg:gap-x-5">
                            <aside className="py-6 px-2 sm:px-6 lg:py-0 lg:px-0 lg:col-span-3">
                                <nav className="space-y-1">
                                    <button
                                        className={classNames(
                                            "w-full text-indigo-700 hover:text-indigo-700 hover:bg-white group rounded-md px-3 py-2 flex items-center text-sm font-medium",
                                            menuSectionExistsInErrors("basics", site_store.errors)
                                                ? "bg-red-100"
                                                : site_store.section === "basics"
                                                ? "bg-gray-100"
                                                : ""
                                        )}
                                        onClick={() => {
                                            updateSiteStore({ section: "basics" });
                                        }}
                                        aria-current="page"
                                    >
                                        <svg
                                            className="text-indigo-500 group-hover:text-indigo-500 flex-shrink-0 -ml-1 mr-3 h-6 w-6"
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            stroke="currentColor"
                                            aria-hidden="true"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth="2"
                                                d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                                            />
                                        </svg>
                                        <span className="truncate">Basics</span>
                                    </button>
                                    <button
                                        className={classNames(
                                            "w-full text-indigo-700 hover:text-indigo-700 hover:bg-white group rounded-md px-3 py-2 flex items-center text-sm font-medium",
                                            menuSectionExistsInErrors("identifiers", site_store.errors)
                                                ? "bg-red-100"
                                                : site_store.section === "identifiers"
                                                ? "bg-gray-100"
                                                : ""
                                        )}
                                        onClick={() => {
                                            updateSiteStore({ section: "identifiers" });
                                        }}
                                        aria-current="page"
                                    >
                                        <svg
                                            className="text-indigo-500 group-hover:text-indigo-500 flex-shrink-0 -ml-1 mr-3 h-6 w-6"
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            stroke="currentColor"
                                            aria-hidden="true"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth="2"
                                                d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                                            />
                                        </svg>
                                        <span className="truncate">Platform Identifiers</span>
                                    </button>
                                    {site_store.mode === "EDIT" && (
                                        <>
                                            <div className="inline-flex">
                                                {realtimeActive.includes(site_store.holdingSite.id!) && (
                                                    <CheckCircleIcon
                                                        className="h-5 w-5 text-green-400"
                                                        aria-hidden="true"
                                                    />
                                                )}
                                                <button
                                                    type="button"
                                                    className="float-none ml-3 bg-blue-500 hover:bg-blue-700 text-white text-xs font-bold py-1 px-3 rounded-full"
                                                    onClick={() => viewRealtime(site_store.holdingSite.id!)}
                                                >
                                                    OPEN REALTIME EDITOR
                                                </button>
                                            </div>
                                        </>
                                    )}
                                </nav>
                            </aside>

                            <div className="space-y-6 sm:px-6 lg:px-0 lg:col-span-9">
                                {site_store.section === "basics" && (
                                    <div className="shadow sm:rounded-md sm:overflow-hidden">
                                        <div className="bg-white py-6 px-4 space-y-6 sm:p-6">
                                            <div>
                                                <h3 className="text-lg leading-6 font-medium text-gray-900">
                                                    Basic site information
                                                </h3>
                                                <p className="mt-1 text-sm text-gray-500">
                                                    After this information is entered and the site is created, we will
                                                    have an ID to work from.
                                                </p>
                                            </div>
                                            <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
                                                <button
                                                    type="button"
                                                    onClick={() => doValidateAndSave()}
                                                    className="bg-indigo-600 border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                                >
                                                    Save
                                                </button>
                                            </div>

                                            <div
                                                className={classNames(
                                                    "grid grid-cols-3 gap-6",
                                                    itemExistsInErrors(`basics#deployActive`, site_store.errors)
                                                        ? "bg-red-100"
                                                        : ""
                                                )}
                                            >
                                                <div
                                                    className={classNames(
                                                        "col-span-3 sm:col-span-2",
                                                        site_store.holdingSite.deployActive !== false
                                                            ? "bg-green-50"
                                                            : "bg-red-50"
                                                    )}
                                                >
                                                    <label
                                                        htmlFor="name"
                                                        className="block text-sm font-medium text-gray-700"
                                                    >
                                                        Site Deployment Status
                                                    </label>
                                                    <div className="mt-1 rounded-md shadow-sm">
                                                        <label className="inline-flex items-center ml-4 mt-2">
                                                            <input
                                                                type="checkbox"
                                                                className="form-checkbox"
                                                                checked={
                                                                    site_store.holdingSite.deployActive !== false
                                                                        ? true
                                                                        : false
                                                                }
                                                                value={"yes"}
                                                                name="deployActive"
                                                                id="deployActive"
                                                                onChange={(e) =>
                                                                    handleSiteChange(
                                                                        e.target.name!,
                                                                        site_store.holdingSite.deployActive
                                                                            ? "no"
                                                                            : "yes"
                                                                    )
                                                                }
                                                            />
                                                            <span className="ml-1 text-sm font-medium text-gray-700">
                                                                Active
                                                            </span>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>

                                            <div
                                                className={classNames(
                                                    "grid grid-cols-3 gap-6",
                                                    itemExistsInErrors(`basics#allPlatforms`, site_store.errors)
                                                        ? "bg-red-100"
                                                        : ""
                                                )}
                                            >
                                                <div className="col-span-3 sm:col-span-2">
                                                    <label
                                                        htmlFor="name"
                                                        className="block text-sm font-medium text-gray-700"
                                                    >
                                                        Allow display of all populated Pixels without UTM restriction
                                                    </label>
                                                    <div className="mt-1 rounded-md shadow-sm">
                                                        <label className="inline-flex items-center ml-4 mt-2">
                                                            <input
                                                                type="checkbox"
                                                                className="form-checkbox"
                                                                checked={site_store.holdingSite.allPlatforms ?? false}
                                                                value={"yes"}
                                                                name="allPlatforms"
                                                                id="allPlatforms"
                                                                onChange={(e) =>
                                                                    handleSiteChange(
                                                                        e.target.name!,
                                                                        site_store.holdingSite.allPlatforms
                                                                            ? "no"
                                                                            : "yes"
                                                                    )
                                                                }
                                                            />
                                                            <span className="ml-1 text-sm font-medium text-gray-700">
                                                                Enabled
                                                            </span>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>

                                            <div
                                                className={classNames(
                                                    "grid grid-cols-3 gap-6",
                                                    itemExistsInErrors(`basics#backendTrackOnly`, site_store.errors)
                                                        ? "bg-red-100"
                                                        : ""
                                                )}
                                            >
                                                <div className="col-span-3 sm:col-span-2">
                                                    <label
                                                        htmlFor="name"
                                                        className="block text-sm font-medium text-gray-700"
                                                    >
                                                        (Facebook Only) Only track via consolidated backend method
                                                    </label>
                                                    <div className="mt-1 rounded-md shadow-sm">
                                                        <label className="inline-flex items-center ml-4 mt-2">
                                                            <input
                                                                type="checkbox"
                                                                className="form-checkbox"
                                                                checked={
                                                                    site_store.holdingSite.backendTrackOnly ?? false
                                                                }
                                                                value={"yes"}
                                                                name="backendTrackOnly"
                                                                id="backendTrackOnly"
                                                                onChange={(e) =>
                                                                    handleSiteChange(
                                                                        e.target.name!,
                                                                        site_store.holdingSite.backendTrackOnly
                                                                            ? "no"
                                                                            : "yes"
                                                                    )
                                                                }
                                                            />
                                                            <span className="ml-1 text-sm font-medium text-gray-700">
                                                                Enabled
                                                            </span>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>

                                            <div
                                                className={classNames(
                                                    "grid grid-cols-3 gap-6",
                                                    itemExistsInErrors(`basics#firmId`, site_store.errors)
                                                        ? "bg-red-100"
                                                        : ""
                                                )}
                                            >
                                                <div className="col-span-3 sm:col-span-2">
                                                    <label
                                                        htmlFor="name"
                                                        className="block text-sm font-medium text-gray-700"
                                                    >
                                                        Firm
                                                    </label>
                                                    <div className="mt-1 rounded-md shadow-sm">
                                                        <select
                                                            id="firmId"
                                                            name="firmId"
                                                            className="focus:ring-indigo-500 focus:border-indigo-500 flex-grow block w-full min-w-0 rounded-none rounded-r-md sm:text-sm border-gray-300"
                                                            value={site_store.holdingSite?.firmId ?? ""}
                                                            onChange={(e) =>
                                                                handleSiteChange(e.target.name!, e.target.value!)
                                                            }
                                                        >
                                                            <option value=""></option>
                                                            {doSort(site_store.firms).map((a, idx) => (
                                                                <option key={idx} value={a.id}>
                                                                    {a.name}
                                                                    {a.displayName ? ` (${a.displayName})` : ""}
                                                                </option>
                                                            ))}
                                                        </select>
                                                        <label className="inline-flex items-center ml-4 mt-2">
                                                            <input
                                                                type="checkbox"
                                                                className="form-checkbox"
                                                                checked={site_store.holdingSite.showFirm ?? false}
                                                                value={"yes"}
                                                                name="showFirm"
                                                                id="showFirm"
                                                                onChange={(e) =>
                                                                    handleSiteChange(
                                                                        e.target.name!,
                                                                        site_store.holdingSite.showFirm ? "no" : "yes"
                                                                    )
                                                                }
                                                            />
                                                            <span className="ml-1 text-sm font-medium text-gray-700">
                                                                show "paid advertisment" banner on site
                                                            </span>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>

                                            <div
                                                className={classNames(
                                                    "grid grid-cols-3 gap-6",
                                                    itemExistsInErrors(`basics#domainId`, site_store.errors)
                                                        ? "bg-red-100"
                                                        : ""
                                                )}
                                            >
                                                <div className="col-span-3 sm:col-span-2">
                                                    <label
                                                        htmlFor="name"
                                                        className="block text-sm font-medium text-gray-700"
                                                    >
                                                        Domain
                                                    </label>
                                                    <div className="mt-1 rounded-md shadow-sm">
                                                        <select
                                                            id="domainId"
                                                            name="domainId"
                                                            className="focus:ring-indigo-500 focus:border-indigo-500 flex-grow block w-full min-w-0 rounded-none rounded-r-md sm:text-sm border-gray-300"
                                                            value={site_store.holdingSite?.domainId ?? ""}
                                                            onChange={(e) =>
                                                                handleSiteChange(e.target.name!, e.target.value!)
                                                            }
                                                        >
                                                            <option value=""></option>
                                                            {doSort(site_store.domains).map((a, idx) => (
                                                                <option key={idx} value={a.id}>
                                                                    {a.name}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>

                                            <div
                                                className={classNames(
                                                    "grid grid-cols-3 gap-6",
                                                    itemExistsInErrors(`basics#uri`, site_store.errors)
                                                        ? "bg-red-100"
                                                        : ""
                                                )}
                                            >
                                                <div className="col-span-3 sm:col-span-2">
                                                    <label
                                                        htmlFor="name"
                                                        className="block text-sm font-medium text-gray-700"
                                                    >
                                                        Path URI (use "/" if you do not want a sub-folder)
                                                    </label>
                                                    <div className="mt-1 rounded-md shadow-sm">
                                                        <input
                                                            type="text"
                                                            name="uri"
                                                            id="uri"
                                                            value={site_store.holdingSite?.uri ?? ""}
                                                            onChange={(e) =>
                                                                handleSiteChange(e.target.name!, e.target.value!)
                                                            }
                                                            placeholder="ex: / or /roundup"
                                                            className="focus:ring-indigo-500 focus:border-indigo-500 flex-grow block w-full min-w-0 rounded-none rounded-r-md sm:text-sm border-gray-300"
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            <div
                                                className={classNames(
                                                    "grid grid-cols-3 gap-6",
                                                    itemExistsInErrors(`basics#appId`, site_store.errors)
                                                        ? "bg-red-100"
                                                        : ""
                                                )}
                                            >
                                                <div className="col-span-3 sm:col-span-2">
                                                    <label
                                                        htmlFor="name"
                                                        className="block text-sm font-medium text-gray-700"
                                                    >
                                                        App
                                                    </label>
                                                    <div className="mt-1 rounded-md shadow-sm">
                                                        <select
                                                            id="appId"
                                                            name="appId"
                                                            className="focus:ring-indigo-500 focus:border-indigo-500 flex-grow block w-full min-w-0 rounded-none rounded-r-md sm:text-sm border-gray-300"
                                                            value={site_store.holdingSite?.appId ?? ""}
                                                            onChange={(e) => {
                                                                handleSiteChange(e.target.name!, e.target.value!);
                                                                if (e.target.value) {
                                                                    specAppSet(
                                                                        (
                                                                            site_store.apps.filter(
                                                                                (a) => a.id === e.target.value
                                                                            ) ?? []
                                                                        ).pop()
                                                                    );
                                                                }
                                                            }}
                                                        >
                                                            <option value=""></option>
                                                            {doSort(site_store.apps).map((a, idx) => (
                                                                <option key={idx} value={a.id}>
                                                                    {a.name}
                                                                    {a.lockTort ? " (locks tort)" : ""}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>

                                            {specApp?.lockTort && (
                                                <div
                                                    className={classNames(
                                                        "grid grid-cols-3 gap-6",
                                                        itemExistsInErrors(`basics#lockedSourceId`, site_store.errors)
                                                            ? "bg-red-100"
                                                            : ""
                                                    )}
                                                >
                                                    <div className="col-span-3 sm:col-span-2">
                                                        <label
                                                            htmlFor="name"
                                                            className="block text-sm font-medium text-gray-700"
                                                        >
                                                            Locked Tort (from App Setting)
                                                        </label>
                                                        <div className="mt-1 rounded-md shadow-sm">
                                                            <select
                                                                id="lockedSourceId"
                                                                name="lockedSourceId"
                                                                className="focus:ring-indigo-500 focus:border-indigo-500 flex-grow block w-full min-w-0 rounded-none rounded-r-md sm:text-sm border-gray-300"
                                                                value={site_store.holdingSite?.lockedSourceId ?? ""}
                                                                onChange={(e) =>
                                                                    handleSiteChange(e.target.name!, e.target.value!)
                                                                }
                                                            >
                                                                <option value=""></option>
                                                                {doSort(site_store.sources).map((a, idx) => (
                                                                    <option key={idx} value={a.id}>
                                                                        {a.name}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}

                                            {!specApp?.lockTort && (
                                                <div
                                                    className={classNames(
                                                        "grid grid-cols-3 gap-6",
                                                        itemExistsInErrors(
                                                            `basics#surveySystemEnable`,
                                                            site_store.errors
                                                        )
                                                            ? "bg-red-100"
                                                            : ""
                                                    )}
                                                >
                                                    <div className="col-span-3 sm:col-span-2">
                                                        <label
                                                            htmlFor="name"
                                                            className="block text-sm font-medium text-gray-700"
                                                        >
                                                            Enable full Survey system v2 intake
                                                        </label>
                                                        <div className="mt-1 rounded-md shadow-sm">
                                                            <label className="inline-flex items-center ml-4 mt-2">
                                                                <input
                                                                    type="checkbox"
                                                                    className="form-checkbox"
                                                                    checked={
                                                                        site_store.holdingSite.surveySystemEnable ??
                                                                        false
                                                                    }
                                                                    value={"yes"}
                                                                    name="surveySystemEnable"
                                                                    id="surveySystemEnable"
                                                                    onChange={(e) =>
                                                                        handleSiteChange(
                                                                            e.target.name!,
                                                                            site_store.holdingSite.surveySystemEnable
                                                                                ? "no"
                                                                                : "yes"
                                                                        )
                                                                    }
                                                                />
                                                                <span className="ml-1 text-sm font-medium text-gray-700">
                                                                    Enabled
                                                                </span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}

                                            {!specApp?.lockTort && !site_store.holdingSite.surveySystemEnable && (
                                                <div
                                                    className={classNames(
                                                        "grid grid-cols-3 gap-6",
                                                        itemExistsInErrors(`basics#surveyId`, site_store.errors)
                                                            ? "bg-red-100"
                                                            : ""
                                                    )}
                                                >
                                                    <div className="col-span-3 sm:col-span-2">
                                                        <label
                                                            htmlFor="name"
                                                            className="block text-sm font-medium text-gray-700"
                                                        >
                                                            Survey
                                                        </label>
                                                        <div className="mt-1 rounded-md shadow-sm">
                                                            <select
                                                                id="surveyId"
                                                                name="surveyId"
                                                                className="focus:ring-indigo-500 focus:border-indigo-500 flex-grow block w-full min-w-0 rounded-none rounded-r-md sm:text-sm border-gray-300"
                                                                value={site_store.holdingSite?.surveyId ?? ""}
                                                                onChange={(e) =>
                                                                    handleSiteChange(e.target.name!, e.target.value!)
                                                                }
                                                            >
                                                                <option value=""></option>
                                                                {doSort(site_store.surveys).map((a, idx) => (
                                                                    <option key={idx} value={a.id}>
                                                                        {a.name}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}

                                            {!specApp?.lockTort && site_store.holdingSite.surveySystemEnable && (
                                                <div
                                                    className={classNames(
                                                        "grid grid-cols-3 gap-6",
                                                        itemExistsInErrors(
                                                            `basics#surveySystemSourceId`,
                                                            site_store.errors
                                                        )
                                                            ? "bg-red-100"
                                                            : ""
                                                    )}
                                                >
                                                    <div className="col-span-3 sm:col-span-2">
                                                        <label
                                                            htmlFor="name"
                                                            className="block text-sm font-medium text-gray-700"
                                                        >
                                                            Survey <b>(V2 Intake Enabled)</b>
                                                        </label>
                                                        <div className="mt-1 rounded-md shadow-sm">
                                                            <select
                                                                id="surveySystemSourceId"
                                                                name="surveySystemSourceId"
                                                                className="focus:ring-indigo-500 focus:border-indigo-500 flex-grow block w-full min-w-0 rounded-none rounded-r-md sm:text-sm border-gray-300"
                                                                value={
                                                                    site_store.holdingSite?.surveySystemSourceId ?? ""
                                                                }
                                                                onChange={(e) =>
                                                                    handleSiteChange(e.target.name!, e.target.value!)
                                                                }
                                                            >
                                                                <option value=""></option>
                                                                {doSort(site_store.sources).map((a, idx) => (
                                                                    <option key={idx} value={a.id}>
                                                                        {a.name}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}

                                            <div
                                                className={classNames(
                                                    "grid grid-cols-3 gap-6",
                                                    itemExistsInErrors(`basics#contentId`, site_store.errors)
                                                        ? "bg-red-100"
                                                        : ""
                                                )}
                                            >
                                                <div className="col-span-3 sm:col-span-2">
                                                    <label
                                                        htmlFor="name"
                                                        className="block text-sm font-medium text-gray-700"
                                                    >
                                                        Content
                                                    </label>
                                                    <div className="mt-1 rounded-md shadow-sm">
                                                        <select
                                                            id="contentId"
                                                            name="contentId"
                                                            className="focus:ring-indigo-500 focus:border-indigo-500 flex-grow block w-full min-w-0 rounded-none rounded-r-md sm:text-sm border-gray-300"
                                                            value={site_store.holdingSite?.contentId ?? ""}
                                                            onChange={(e) =>
                                                                handleSiteChange(e.target.name!, e.target.value!)
                                                            }
                                                        >
                                                            <option value=""></option>
                                                            {doSort(site_store.contents).map((a, idx) => (
                                                                <option key={idx} value={a.id}>
                                                                    {a.name}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
                                            <button
                                                type="button"
                                                onClick={() => doValidateAndSave()}
                                                className="bg-indigo-600 border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                            >
                                                Save
                                            </button>
                                        </div>
                                    </div>
                                )}
                                {site_store.section === "identifiers" && (
                                    <div className="shadow sm:rounded-md sm:overflow-hidden">
                                        <div className="bg-white py-6 px-4 space-y-6 sm:p-6">
                                            <div>
                                                <h3 className="text-lg leading-6 font-medium text-gray-900">
                                                    Platform Identifiers
                                                </h3>
                                                <p className="mt-1 text-sm text-gray-500">
                                                    Please supply all identifiers
                                                </p>
                                            </div>
                                            <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
                                                <button
                                                    type="button"
                                                    onClick={() => doValidateAndSave()}
                                                    className="bg-indigo-600 border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                                >
                                                    Save
                                                </button>
                                            </div>
                                            {Object.keys(site_store.platformIdentifiers).map((platform, ip) =>
                                                Object.keys(site_store.platformIdentifiers[platform]).map(
                                                    (pname, idx) => (
                                                        <div className="grid grid-cols-3 gap-6" key={idx}>
                                                            <div className="col-span-3 sm:col-span-2">
                                                                <label
                                                                    htmlFor="name"
                                                                    className="block text-sm font-medium text-gray-700"
                                                                >
                                                                    <b>{platform}</b> - {pname}
                                                                </label>
                                                                <div className="mt-1 rounded-md shadow-sm flex">
                                                                    <input
                                                                        type="text"
                                                                        name={`${platform}#${pname}`}
                                                                        id={`${platform}#${pname}`}
                                                                        value={
                                                                            site_store.platformIdentifiers[platform][
                                                                                pname
                                                                            ]["value"] ?? ""
                                                                        }
                                                                        onChange={(e) =>
                                                                            handleHoldingPlatformIdentifierChange(
                                                                                e.target.name!,
                                                                                e.target.value!
                                                                            )
                                                                        }
                                                                        placeholder=""
                                                                        className="focus:ring-indigo-500 focus:border-indigo-500 flex-grow block w-full min-w-0 rounded-none rounded-r-md sm:text-sm border-gray-300"
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                )
                                            )}
                                        </div>
                                        <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
                                            <button
                                                type="button"
                                                onClick={() => doValidateAndSave()}
                                                className="bg-indigo-600 border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                            >
                                                Save
                                            </button>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                )}
            </main>
            {modalInfo.open && <Confirm info={modalInfo} />}
        </>
    );
}

export default SitesModify;
