import { useState, useEffect } from "react";

import Header from "../components/Header";
import Loader from "../components/Loader";

import { getDomains } from "billeci-private-sitebuilder-schema/dist/graphql/queries";

import DomainRenewActivity from "../components/DomainRenewActivity";

import { API } from "aws-amplify";

import { Domain } from "billeci-private-sitebuilder-schema/dist/API";

function Domains() {
    const [domains, domainsSet] = useState([] as Domain[]);
    const [loading, loadingSet] = useState(true);

    const loadData = async () => {
        const response: any = await API.graphql({ query: getDomains });
        domainsSet(response.data.getDomains as unknown as Domain[]);
        loadingSet(false);
    };

    const updateRenewStatus = (index: number, s: boolean) => {
        domains[index].renewActive = s;
        domainsSet(domains);
    };

    useEffect(() => {
        loadData();
    }, []);

    return (
        <>
            <Header title="Domains" action_button={{ link: "/domains/new", text: "Register New" }} />
            <main>
                {loading ? (
                    <Loader />
                ) : (
                    <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
                        <div className="flex flex-col">
                            <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                                    <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                                        <table className="min-w-full divide-y divide-gray-200">
                                            <thead className="bg-gray-50">
                                                <tr>
                                                    <th
                                                        scope="col"
                                                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                                    >
                                                        Name
                                                    </th>
                                                    <th
                                                        scope="col"
                                                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                                    >
                                                        Renew Status
                                                    </th>
                                                    <th
                                                        scope="col"
                                                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                                    >
                                                        Registrar
                                                    </th>
                                                    <th
                                                        scope="col"
                                                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                                    >
                                                        Price
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody className="bg-white divide-y divide-gray-200">
                                                {domains?.map((domain: any, index: number) => (
                                                    <tr key={index} className={index % 2 === 0 ? "bg-gray-100" : ""}>
                                                        <td className="px-6 py-4 whitespace-nowrap">
                                                            <div className="text-sm text-gray-900">
                                                                <a href={`http://${domain.name}`}>{domain.name}</a>
                                                            </div>
                                                        </td>
                                                        <td className="px-6 py-4 whitespace-nowrap">
                                                            <DomainRenewActivity
                                                                domain={domain}
                                                                index={index}
                                                                callback={updateRenewStatus}
                                                            ></DomainRenewActivity>
                                                        </td>
                                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                                            {domain.registrar}
                                                        </td>
                                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                                            ${domain.price}
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </main>
        </>
    );
}

export default Domains;
