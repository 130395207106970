import { useState, useEffect } from "react";

import Header from "../components/Header";
import Loader from "../components/Loader";

import { getFirms } from "billeci-private-sitebuilder-schema/dist/graphql/queries";

import { Link } from "react-router-dom";

import { API } from "aws-amplify";

import { Firm } from "billeci-private-sitebuilder-schema/dist/API";

function Firms() {
    const [firms, firmsSet] = useState([] as Firm[]);
    const [loading, loadingSet] = useState(true);

    useEffect(() => {
        const loadData = async () => {
            const response: any = await API.graphql({ query: getFirms });
            firmsSet(response.data.getFirms);
            loadingSet(false);
        };
        loadData();
    }, []);

    return (
        <>
            <Header title="Firms" action_button={{ link: "/firms/new", text: "Create New" }} />
            <main>
                {loading ? (
                    <Loader />
                ) : (
                    <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
                        <div className="flex flex-col">
                            <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                                    <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                                        <table className="min-w-full divide-y divide-gray-200">
                                            <thead className="bg-gray-50">
                                                <tr>
                                                    <th
                                                        scope="col"
                                                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                                    >
                                                        Name
                                                    </th>
                                                    <th
                                                        scope="col"
                                                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                                    >
                                                        Description
                                                    </th>
                                                    <th scope="col" className="relative px-6 py-3">
                                                        <span className="sr-only">Edit</span>
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody className="bg-white divide-y divide-gray-200">
                                                {firms.map((firm: any, index: number) => (
                                                    <tr key={index} className={index % 2 === 0 ? "bg-gray-100" : ""}>
                                                        <td className="px-6 py-4 whitespace-nowrap">
                                                            <div className="text-sm text-gray-900">{firm.name}</div>
                                                        </td>
                                                        <td className="px-6 py-4 whitespace-nowrap">
                                                            <div className="text-sm text-gray-900">
                                                                {firm.displayName}
                                                            </div>
                                                        </td>
                                                        <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                                                            <Link
                                                                to={`/firms/${firm.id}`}
                                                                className="text-indigo-600 hover:text-indigo-900"
                                                            >
                                                                Edit
                                                            </Link>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </main>
        </>
    );
}

export default Firms;
