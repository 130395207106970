import { useEffect } from "react";
import Amplify, { Auth, Hub } from "aws-amplify";
import awsconfig from "./aws-exports";

import { parseAuthUser, resetAuthUser, updateAuthUser } from "billeci-private-effector-stores/dist/AuthUserStore";

import Main from "./pages/Main";

Amplify.configure(awsconfig);

function App() {
    useEffect(() => {
        Hub.listen("auth", ({ payload: { event, data } }) => {
            switch (event) {
                case "signIn":
                case "cognitoHostedUI":
                    getUser().then((userData) => {
                        parseAuthUser(userData);
                    });
                    break;
                case "signOut":
                    resetAuthUser();
                    break;
                case "signIn_failure":
                case "cognitoHostedUI_failure":
                    console.log("Sign in failure", data);
                    break;
            }
        });

        getUser().then((userData) => {
            if (userData) {
                parseAuthUser(userData);
            } else {
                updateAuthUser({
                    userinfo: { isParsed: true },
                });
            }
        });
    }, []);

    function getUser() {
        return Auth.currentAuthenticatedUser()
            .then((userData) => userData)
            .catch(() => console.log("Not signed in"));
    }

    return <Main />;
}

export default App;
